import { string, oneOfType, arrayOf } from 'prop-types';
import React, { useContext } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { user as userAPI } from 'web/services/api';
import { AuthContext } from 'web/utils/context';
import { isAuthFinished } from '@web/utils/other';

const UnauthenticatedRoute = props => {
  const history = useHistory();
  const { token, campaign, setUser } = useContext(AuthContext);
  const { pathname } = useLocation();

  const isUnauthenticatedRoute =
    pathname.includes('auth') ||
    pathname.includes('pledge_to_vote') ||
    pathname.includes('check_registration') ||
    pathname.includes('confirm_address') ||
    pathname.includes('voting_information');

  React.useEffect(() => {
    // Keep logged in users on magic-auth page if they landed there
    if (token && pathname.includes('auth/magic-link')) {
      return;
    }

    // redirect when someone is logged in but lands on any other auth page
    // (this includes visiting app domiain directly)
    if (token && pathname.includes('auth')) {
      userAPI.getProfile().then(({ data }) => {
        const { data: userData } = data;
        setUser(userData);

        if (userData?.is_campaign_admin) {
          // hard reload (switch to) admin react app
          window.location.href = '/admin';
        } else {
          if (isAuthFinished(userData)) {
            history.push('/frontline');
          } else {
            if (campaign?.slug) {
              history.push(`/auth/join-campaign/${campaign.slug}`);
            } else {
              history.push('/auth/join-campaign');
            }
          }
        }
      });
    }
    // Purpusefully only running on initial page loads to redirect anyone that lands on these pages while logged in
    // but not when redirected
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!token || !isUnauthenticatedRoute) {
    return <Route {...props} />;
  }

  return <Route {...props} />;
};

UnauthenticatedRoute.propTypes = {
  path: oneOfType([string, arrayOf(string)]).isRequired,
};

export default UnauthenticatedRoute;
