import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ImpactiveModal from '@web/components/common/ImpactiveModal';
import ImpactiveButton from '@web/components/ImpactiveButton';

const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.colors.borderColor};
  border-radius: 25px;
  padding: 25px;
  gap: 25px;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-size: 18px;
  text-align: center;
  margin-bottom: 24px;

  ${({ theme }) => theme.fonts.regular};
`;

const TerminateButton = styled(ImpactiveButton)`
  width: 100%;
  height: 45px;
  font-family: ${({ theme }) => theme.fonts.boldItalic};
  font-size: 16px;
  height: 44px;
  letter-spacing: 1.4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TerminateText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  letter-spacing: 1.5px;
  text-align: center;
  ${({ theme }) => theme.fonts.bold};
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-size: 30px;
  text-align: center;
  margin-bottom: 16px;

  ${({ theme }) => theme.fonts.regular};
`;

const TerminateModal = ({ isModalOpen, modalHandler, name, onTerminate }) => {
  const { t } = useTranslation();
  return (
    <ImpactiveModal visible={isModalOpen} onCancel={() => modalHandler(false)}>
      <ModalContent>
        <Title>{t('activity.phone_bank.patch_through.terminate_question')}</Title>
        <Text>{t('activity.phone_bank.patch_through.terminate_warning', { name })}</Text>
        <TerminateButton onClick={onTerminate}>
          <TerminateText>{t('activity.phone_bank.patch_through.terminate_yes')}</TerminateText>
        </TerminateButton>
      </ModalContent>
    </ImpactiveModal>
  );
};

TerminateModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  modalHandler: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onTerminate: PropTypes.func.isRequired,
};

export default TerminateModal;
