import * as React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import BackButton from './components/BackButton';
import Button from '@web/components/DialerActivity/components/Button';
import ScriptBlock from '@web/components/DialerActivity/components/ScriptBlock';
import { voterConnected } from '@web/reducers/dialerReducer/dialerMachine';
import { useDispatch, useSelector } from 'react-redux';
import { setDisabledPatchThrough } from '@web/reducers/dialerReducer';
import usePatchThrough from '@web/components/DialerActivity/usePatchThrough';
import PatchThroughInfo from './components/PatchThroughInfo';

const StyledInteractiveScript = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin-top: 18px;
`;
const ScrollView = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
`;

const Text = styled.p`
  font-family: ${({ theme }) => theme.fonts.semibold};
  font-size: 18px;
  line-height: 28px;
  margin: 0 auto;
  max-width: 470px;
  text-align: center;
`;

function InteractiveScript({
  answers = [],
  connectedContact,
  handleDoneClick = () => null,
  isEdit = false,
  questions = [],
  setAnswers,
  isPatchThroughConnected,
}) {
  const { t } = useTranslation();
  const { disabledPatchThrough, currentState } = useSelector(state => state.dialer);
  const dispatch = useDispatch();

  const { handlePatchThrough } = usePatchThrough();

  const [currentQuestion, setCurrentQuestion] = React.useState(questions[0]);
  const [endReached, setEndReached] = React.useState(false);

  React.useEffect(() => {
    if (!currentQuestion && questions.length) {
      setCurrentQuestion(questions[0]);
    }
  }, [currentQuestion, questions]);

  function handleSelect(option) {
    if (option?.patch_through) {
      // ignore patch through click in any state other than voterConnected
      if (currentState !== voterConnected) return;
      // if the option is a patch through, disable it until third party call is connected
      dispatch(setDisabledPatchThrough([option.reference]));
      handlePatchThrough();
      return;
    }

    setAnswers(
      answers.concat([
        {
          answerReference: option.reference,
          questionReference: currentQuestion.reference,
        },
      ]),
    );
    if (option?.next_item) {
      setCurrentQuestion(questions.find(question => question.reference === option.next_item));
    } else {
      setEndReached(true);
    }
  }

  function goBack() {
    const questionIndex = answers.length;
    const prevQuestionRef = answers[questionIndex - 1].questionReference;
    setCurrentQuestion(questions.find(q => q.reference === prevQuestionRef));
    setAnswers(answers.filter((el, i) => i < questionIndex - 1));
    setEndReached(false);
  }

  function getSelected() {
    const questionRef = currentQuestion?.reference;
    const answerObj = answers.find(el => el.questionReference === questionRef);
    if (answerObj) {
      return answerObj.answerReference;
    }
    return null;
  }

  const showBackButton = answers.length > 0;

  const endOfScriptMessage = `${t('activity.phone_bank.script_end')} ${t(
    'activity.phone_bank.script_end_continue',
  )}`;
  const endScriptEditingMessage = t('activity.phone_bank.script_end');
  const endMessage = isEdit ? endScriptEditingMessage : endOfScriptMessage;

  if (!currentQuestion) return null;

  if (isPatchThroughConnected) {
    return (
      <StyledInteractiveScript>
        <ScrollView>
          <PatchThroughInfo />
        </ScrollView>
      </StyledInteractiveScript>
    );
  }

  return (
    <StyledInteractiveScript>
      <ScrollView>
        {endReached && <Text>{endMessage}</Text>}
        {!endReached && (
          <ScriptBlock
            connectedContact={connectedContact}
            onAnswerSelect={handleSelect}
            selectedAnswerRef={getSelected()}
            answersDisabled={disabledPatchThrough}
            {...currentQuestion}
          />
        )}
        {showBackButton && <BackButton onClick={goBack} />}
      </ScrollView>
      {isEdit && <Button onClick={handleDoneClick}>I'm Done Editing</Button>}
    </StyledInteractiveScript>
  );
}

InteractiveScript.propTypes = {
  answers: PropTypes.array,
  connectedContact: PropTypes.object.isRequired,
  handleDoneClick: PropTypes.func,
  isEdit: PropTypes.bool,
  isPatchThroughConnected: PropTypes.bool,
  questions: PropTypes.array,
  setAnswers: PropTypes.func.isRequired,
};

export default InteractiveScript;
