import React from 'react';
import styled from 'styled-components';
import { bool, oneOf } from 'prop-types';
import darkLogo from '../../../images/app-logo-primary.png';
import lightLogo from '../../../images/app-logo-secondary.png';

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${({ $isPoweredBy }) => ($isPoweredBy ? '0px' : '1.875rem 0')};
`;

const Logo = styled.img`
  height: ${({ $isPoweredBy }) => ($isPoweredBy ? '1.5rem' : '27px')};
  width: auto;
`;

const ImpactiveLogo = ({ type, isPoweredBy }) => {
  return (
    <LogoContainer $isPoweredBy={isPoweredBy}>
      <Logo
        $isPoweredBy={isPoweredBy}
        src={type === 'light' ? lightLogo : darkLogo}
        alt="Impactive logo"
      />
    </LogoContainer>
  );
};

ImpactiveLogo.propTypes = {
  isPoweredBy: bool,
  type: oneOf(['dark', 'light']),
};

ImpactiveLogo.defaultProps = {
  isPoweredBy: false,
  type: 'dark',
};

export default ImpactiveLogo;
