import { message } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { identify } from '../../services/analytics';
import { auth, users } from '../../services/api';
import { AuthContext } from '../../utils/context';
import Loading from '../common/Loading';
import { setAppLanguage } from '../i18n';
import { getRedirectAfterLoginURL, getAndClearRedirectAfterLoginURL } from 'web/services/session';
import ImpactiveOrCampaignLogo from './Components/ImpactiveOrCampaignLogo';
import PoweredByImpactive from './Components/PoweredByImpactive';
import LanguageSelect from './LanguageSelect';

import EmailAuth from './Components/EmailAuth';
import './auth.scss';

const EmailAuthPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { campaign_slug } = useParams();

  const title = campaign_slug ? t('titles.sign_up') : t('titles.sign_in');
  useDocumentTitle(title);

  const { setAuth, onboardingData, campaign, isLoading, setCampaignSlug } = useContext(AuthContext);

  const provider = 'email';

  useEffect(() => {
    setCampaignSlug(campaign_slug);
  }, [campaign_slug, setCampaignSlug]);

  const handleRedirection = async user => {
    const canViewAdmin = user.is_campaign_admin || user.can_admin_accounts;
    // If the user can access admin and no redirectURL is set - go to admin
    const hasRedirectURL = !!getRedirectAfterLoginURL();
    if (canViewAdmin && !hasRedirectURL) {
      // hard reload (switch to) admin react app
      window.location.href = '/admin';
      return;
    }

    // Join the campaign if auth happened on campaign screen
    // this will only be relevant for signup action that is currently disabled
    if (campaign_slug && campaign) {
      await users.followCampaign({
        campaign_id: campaign.id,
        campaign_name: campaign.name,
        campaign_slug: campaign.slug,
      });
    }

    // redirect after login if URL is stored
    const redirectURL = getAndClearRedirectAfterLoginURL();
    if (redirectURL) {
      history.push(redirectURL);
      return;
    }

    // Fallback - throw them to frontline
    history.push('/frontline');
  };

  const onLogInHandler = (email, password) => {
    return auth
      .signIn(email, password, provider)
      .then(data => {
        const { token, user } = data;
        setAuth(token);
        setAppLanguage(user.locale);
        identify(user.id);

        // Redirect after email/password based login
        return handleRedirection(user);
      })
      .catch(unsetAuthHandler);
  };

  // Signup with email/password isn't surfaced in the web UI anymore
  // this is kept for reference and will be removed when signup is re-enabled
  // const onSignUpHandler = (email, password) => {
  //   // throw new Error('Unsupported email/password signup');
  //   return auth
  //     .signUp(email, password, provider)
  //     .then(data => {
  //       const { token, user } = data;
  //       setAuth(token);
  //       setAppLanguage(user.locale);
  //       identify(user.id);

  //       // Redirect after email/password based signup
  //       // TODO: validate redirection logic when/if this is no longer hidden in the UI
  //       return handleRedirection(user);
  //     })
  //     .catch(unsetAuthHandler);
  // };

  const onSubmitClick = (email, password) => {
    // use only onLogInHandler for now, but keep onSignUpHandler for reference
    onLogInHandler(email, password);
  };

  const unsetAuthHandler = ({ response }) => {
    // unset auth token
    setAuth(null);

    // display error message
    const { data: { error, message: errorMessage } = {} } = response;
    message.error(errorMessage || error || t('errors.action_failed'));
  };

  const onBackClick = useCallback(() => {
    if (campaign_slug) {
      history.push(`/auth/signup/${campaign_slug}`);
    } else {
      history.push('/auth/login');
    }
  }, [history, campaign_slug]);

  if (isLoading) {
    return (
      <div className="d-flex flex-row justify-content-center">
        <Loading />
      </div>
    );
  }

  if (!onboardingData.language) {
    return <LanguageSelect />;
  }

  return (
    <div className="h-100 bg-auth-cyan d-flex flex-column align-items-center justify-content-center">
      <ImpactiveOrCampaignLogo campaign={campaign} />

      <div className="card fixed-auth">
        <div className="card-title bolder mb-4">{t('auth.login')}</div>

        <div className="my-4">
          <EmailAuth onSubmitClick={onSubmitClick} buttonText={t('auth.login')} />
        </div>

        <div className="d-flex flex-row align-items-center justify-content-center mt-5 text-center">
          <div className="text-underline pointer" onClick={onBackClick}>
            {t('auth.back')}
          </div>
        </div>
      </div>

      {campaign && <PoweredByImpactive />}
    </div>
  );
};

EmailAuthPage.propTypes = {
  isSignup: PropTypes.bool,
};

EmailAuthPage.defaultProps = {};

export default EmailAuthPage;
