import React, { useContext, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import queryString from 'query-string';

import { getLocalizedOnboardingData, sortByDisplayOrderFn } from 'web/utils/other';
import { track } from '../../../services/analytics';
import { campaigns, users as usersApi } from '../../../services/api';
import { AuthContext } from '../../../utils/context';
import { isAuthFinished, isShowCampaignOnboarding } from '@web/utils/other';
import ImpactiveModal from '../ImpactiveModal';
import UserDetails from './UserDetails';
import Instructions from './Instructions';
import CustomFields from './CustomFields';
import { useLocation, useHistory } from 'react-router-dom';

const USER_DETAIL_SCREEN = 'user_detail_screen';
const INSTRUCTIONS_SCREEN = 'instructions_screen';
const CUSTOM_FIELDS_SCREEN = 'custom_fields_screen';

/**
 * Modal dialog that shows welcome instructions when user intially logs in/signs up
 */
const OnboardingModal = ({ onCloseCallback }) => {
  const { search } = useLocation();
  const { showOnboarding } = queryString.parse(search);
  const { t } = useTranslation();
  const { token, user, setUser, isLockedToCampaign } = useContext(AuthContext);
  const [displayModal, setDisplayModal] = useState(true);
  const [campaign, setCampaign] = useState(null);
  const [CFs, setCFs] = useState([]);
  const history = useHistory();

  const [screen, setScreen] = useState(null);

  const clearSearch = useCallback(() => {
    if (showOnboarding) {
      history.replace({
        search: '',
      });
    }
  }, [history, showOnboarding]);

  const closeModal = useCallback(() => {
    clearSearch();
    track('CLOSED_ONBOARDING_MODAL');
    usersApi.updateMe({ onboarding_viewed: true }).then(res => {
      const {
        data: { data: current_user },
      } = res;
      setUser(current_user);
      setDisplayModal(false);
      onCloseCallback();
    });
  }, [setUser, onCloseCallback, clearSearch]);

  useEffect(() => {
    track('VIEW_INSTRUCTIONS_SCREEN');
  }, []);

  useEffect(() => {
    // We only care about onboarding instructions for logged in users
    if (!token || !user) return;

    const { onboarding_viewed } = user;

    // Only continue if user has not seen welcome instructions yet
    // Or if user joined a new campaign via join code
    if (onboarding_viewed && !showOnboarding) return;

    const campaignId = user.locked_campaign_id || user.last_campaign_id;

    if (!campaignId) {
      // Fetch campaign if locked in or coming from campaign screen, otherwise just display default welcome screen
      if (isAuthFinished(user)) {
        return;
      } else {
        setScreen(USER_DETAIL_SCREEN);
        setDisplayModal(true);
      }
      return;
    }

    campaigns.getCampaign({ id: campaignId }).then(({ data: { data } }) => {
      const localizedData = getLocalizedOnboardingData(data);
      const onboardingCFs =
        data.custom_fields?.filter(cf => cf.onboarding_question)?.sort(sortByDisplayOrderFn) || [];

      setCampaign(localizedData);
      setCFs(onboardingCFs);

      if (isShowCampaignOnboarding(localizedData) || !!onboardingCFs?.length) {
        setScreen(isShowCampaignOnboarding(data) ? INSTRUCTIONS_SCREEN : CUSTOM_FIELDS_SCREEN);
        setDisplayModal(true);
      }
    });

    // Retrigger effect whenever context flags are updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLockedToCampaign, user, showOnboarding]);

  const onUserDetailSubmit = useCallback(
    (values, setSubmitting) => {
      clearSearch();
      setSubmitting(true);
      const { first_name, last_name, phone, zip, locale } = values;

      usersApi
        .updateMe({
          first_name,
          last_name,
          locale,
          phone: `+${phone}`,
          supplied_zip_code: zip,
        })
        .then(() => {
          if (isShowCampaignOnboarding(campaign)) {
            setScreen(INSTRUCTIONS_SCREEN);
            return;
          }
          if (CFs?.length) {
            setScreen(CUSTOM_FIELDS_SCREEN);
            return;
          }
          closeModal();
        })
        .catch(error => {
          message.error(
            error?.response?.data?.message || error?.message || t('common.something_went_wrong'),
          );
        })
        .finally(() => setSubmitting(false));
    },
    [CFs, campaign, closeModal, clearSearch, t],
  );

  const onInstructionsFinish = useCallback(() => {
    if (CFs?.length) {
      clearSearch();
      setScreen(CUSTOM_FIELDS_SCREEN);
    } else {
      closeModal();
    }
  }, [CFs, closeModal, clearSearch]);

  const handleSubmitReport = useCallback(() => {
    if (!campaign || !user || !CFs?.length) {
      return;
    }

    const userReportBody = {
      campaign_id: campaign.id,
      customizations_attributes: CFs.map(c => {
        return {
          custom_field_id: c.id,
          value: c.value || '',
        };
      }),
      reportable_id: user.id,
      reportable_type: 'User',
    };

    campaigns.submitOnboardingReport({ campaign_id: campaign.id }, userReportBody).then(() => {
      closeModal();
    });
  }, [campaign, user, CFs, closeModal]);

  return (
    <ImpactiveModal visible={displayModal && screen} closable={false} footer={null} width={700}>
      <div className="d-flex flex-column align-items-center">
        {screen === USER_DETAIL_SCREEN ? (
          <UserDetails onSubmit={onUserDetailSubmit} user={user} />
        ) : screen === INSTRUCTIONS_SCREEN ? (
          <Instructions campaign={campaign} onInstructionsFinish={onInstructionsFinish} />
        ) : screen === CUSTOM_FIELDS_SCREEN ? (
          <CustomFields
            campaign={campaign}
            CFs={CFs}
            setCFs={setCFs}
            handleSubmitReport={handleSubmitReport}
          />
        ) : (
          <div />
        )}
      </div>
    </ImpactiveModal>
  );
};

OnboardingModal.propTypes = {
  onCloseCallback: PropTypes.func,
};

OnboardingModal.defaultProps = {
  onCloseCallback: () => {},
};

export default OnboardingModal;
