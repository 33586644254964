import { createMachine } from 'xstate';
import machineDebugger from './machineDebugger';

// Transitions
export const CALL_SUCCESSFUL = 'CALL_SUCCESSFUL';
export const CALL_UNSUCCESSFUL = 'CALL_UNSUCCESSFUL';
export const END_SESSION = 'END_SESSION';
export const EXIT_ACTIVITY = 'EXIT_ACTIVITY';
export const NO_TAGS_OR_CUSTOM_FIELDS = 'NO_TAGS_OR_CUSTOM_FIELDS';
export const OUT_OF_SYNC_VOTER_HANG_UP = 'OUT_OF_SYNC_VOTER_HANG_UP';
export const PHONE_BANK_ENDED = 'PHONE_BANK_ENDED';
export const PHONE_BANK_NOT_STARTED = 'PHONE_BANK_NOT_STARTED';
export const PHONE_BANK_PAUSED = 'PHONE_BANK_PAUSED';
export const PHONE_BANK_STARTED = 'PHONE_BANK_STARTED';
export const QUEUE_NEXT_CALL = 'QUEUE_NEXT_CALL';
export const QUEUE_CALL_ERROR = 'QUEUE_CALL_ERROR';
export const RECONNECTED = 'RECONNECTED';
export const RESPONSES_SUBMITTED = 'RESPONSES_SUBMITTED';
export const SESSION_STATS_VIEWED = 'SESSION_STATS_VIEWED';
export const SESSION_STATS_VIEWED_AUTO_CONNECT = 'SESSION_STATS_VIEWED_AUTO_CONNECT';
export const TAGS_AND_CUSTOM_FIELDS_SUBMITTED = 'TAGS_AND_CUSTOM_FIELDS_SUBMITTED';
export const VOLUNTEER_ANSWERED = 'VOLUNTEER_ANSWERED';
export const VOLUNTEER_DISCONNECTED = 'VOLUNTEER_DISCONNECTED';
export const VOLUNTEER_NOT_CONNECTED = 'VOLUNTEER_NOT_CONNECTED'; // different from above
export const VOLUNTEER_HANG_UP = 'VOLUNTEER_HANG_UP';
export const VOTER_CONNECTED = 'VOTER_CONNECTED';
export const PATCH_THROUGH_CONNECTED = 'PATCH_THROUGH_CONNECTED';
export const VOTER_HANG_UP = 'VOTER_HANG_UP';

// States
export const callOutcome = 'callOutcome';
export const connectingToVoter = 'connectingToVoter';
export const dialingVolunteer = 'dialingVolunteer';
export const disconnected = 'disconnected';
export const idle = 'idle';
export const autoConnectLoader = 'autoConnectLoader';
export const phoneBankEnded = 'phoneBankEnded';
export const phoneBankNotStarted = 'phoneBankNotStarted';
export const phoneBankPaused = 'phoneBankPaused';
export const sessionEnded = 'sessionEnded';
export const sessionStats = 'sessionStats';
export const tagsAndCustomFields = 'tagsAndCustomFields';
export const viewResponses = 'viewResponses';
export const volunteerDisconnected = 'volunteerDisconnected';
export const voterConnected = 'voterConnected';
export const patchThroughConnected = 'patchThroughConnected';
export const patchThroughSuccess = 'patchThroughSuccess';

const dialerMachine = createMachine({
  id: 'dialerMachine',
  initial: 'dialingVolunteer',
  states: {
    // Copied state for auto queue
    [autoConnectLoader]: {
      on: {
        [END_SESSION]: {
          target: sessionEnded,
        },
        [EXIT_ACTIVITY]: {
          target: dialingVolunteer,
        },
        [PATCH_THROUGH_CONNECTED]: {
          target: patchThroughConnected,
        },
        [PHONE_BANK_ENDED]: {
          target: phoneBankEnded,
        },
        [PHONE_BANK_NOT_STARTED]: {
          target: phoneBankNotStarted,
        },
        [PHONE_BANK_PAUSED]: {
          target: phoneBankPaused,
        },
        [QUEUE_CALL_ERROR]: {
          target: idle, // MTS - This helps us debug in Sentry since there will be a breadcrumb.
        },
        [QUEUE_NEXT_CALL]: {
          target: connectingToVoter,
        },
        [VOLUNTEER_DISCONNECTED]: {
          target: volunteerDisconnected,
        },
        [VOLUNTEER_NOT_CONNECTED]: {
          target: volunteerDisconnected,
        },
        // MTS - 2/22/22 We found an edge case where the pusher event can reach the
        // client before the "Queue Next Call" request has resolved on the client.
        [VOTER_CONNECTED]: {
          target: voterConnected,
        },
        [VOTER_HANG_UP]: {
          target: idle, // self transition
        },
      },
    },
    [callOutcome]: {
      on: {
        [CALL_SUCCESSFUL]: {
          target: viewResponses,
        },
        [CALL_UNSUCCESSFUL]: {
          target: sessionStats,
        },
        [EXIT_ACTIVITY]: {
          target: dialingVolunteer,
        },
        [VOLUNTEER_DISCONNECTED]: {
          target: callOutcome, // self transition
        },
        [VOLUNTEER_HANG_UP]: {
          target: callOutcome, // self transition
        },
        [VOTER_HANG_UP]: {
          target: callOutcome, // self transition
        },
      },
    },
    [connectingToVoter]: {
      on: {
        [EXIT_ACTIVITY]: {
          target: dialingVolunteer,
        },
        [OUT_OF_SYNC_VOTER_HANG_UP]: {
          target: idle,
        },
        [PHONE_BANK_ENDED]: {
          target: phoneBankEnded,
        },
        [PHONE_BANK_PAUSED]: {
          target: phoneBankPaused,
        },
        [VOLUNTEER_DISCONNECTED]: {
          target: volunteerDisconnected,
        },
        [VOLUNTEER_HANG_UP]: {
          target: idle,
        },
        [VOTER_CONNECTED]: {
          target: voterConnected,
        },
      },
    },
    [dialingVolunteer]: {
      on: {
        [EXIT_ACTIVITY]: {
          target: dialingVolunteer, // self transition
        },
        [PHONE_BANK_STARTED]: {
          target: dialingVolunteer, // self transition
        },
        [VOLUNTEER_ANSWERED]: {
          target: idle,
        },
      },
    },
    [idle]: {
      on: {
        [END_SESSION]: {
          target: sessionEnded,
        },
        [EXIT_ACTIVITY]: {
          target: dialingVolunteer,
        },
        [PHONE_BANK_ENDED]: {
          target: phoneBankEnded,
        },
        [PHONE_BANK_NOT_STARTED]: {
          target: phoneBankNotStarted,
        },
        [PHONE_BANK_PAUSED]: {
          target: phoneBankPaused,
        },
        [QUEUE_CALL_ERROR]: {
          target: idle, // MTS - This helps us debug in Sentry since there will be a breadcrumb.
        },
        [QUEUE_NEXT_CALL]: {
          target: connectingToVoter,
        },
        [VOLUNTEER_DISCONNECTED]: {
          target: volunteerDisconnected,
        },
        [VOLUNTEER_NOT_CONNECTED]: {
          target: volunteerDisconnected,
        },
        // MTS - 2/22/22 We found an edge case where the pusher event can reach the
        // client before the "Queue Next Call" request has resolved on the client.
        [VOTER_CONNECTED]: {
          target: voterConnected,
        },
        [VOTER_HANG_UP]: {
          target: idle, // self transition
        },
      },
    },
    [patchThroughConnected]: {
      on: {
        [CALL_SUCCESSFUL]: {
          target: patchThroughSuccess,
        },
        [EXIT_ACTIVITY]: {
          target: dialingVolunteer,
        },
        // We need this transition because a voter can be connected prior to
        // the app receiving the response back from the QUEUE_NEXT_CALL network
        // request.
        [QUEUE_NEXT_CALL]: {
          target: voterConnected, // self transition
        },
        [VOLUNTEER_DISCONNECTED]: {
          target: volunteerDisconnected,
        },
        [VOLUNTEER_HANG_UP]: {
          target: patchThroughSuccess,
        },
        [VOTER_HANG_UP]: {
          target: patchThroughSuccess,
        },
      },
    },
    [patchThroughSuccess]: {
      on: {
        [CALL_SUCCESSFUL]: {
          target: viewResponses,
        },
        [CALL_UNSUCCESSFUL]: {
          target: sessionStats,
        },
        [EXIT_ACTIVITY]: {
          target: dialingVolunteer,
        },
        [VOLUNTEER_DISCONNECTED]: {
          target: patchThroughSuccess, // self transition
        },
        [VOLUNTEER_HANG_UP]: {
          target: patchThroughSuccess, // self transition
        },
        [VOTER_HANG_UP]: {
          target: patchThroughSuccess, // self transition
        },
      },
    },
    [phoneBankEnded]: {
      on: {
        [END_SESSION]: {
          target: sessionEnded,
        },
        [EXIT_ACTIVITY]: {
          target: dialingVolunteer,
        },
        [PATCH_THROUGH_CONNECTED]: {
          target: patchThroughConnected,
        },
        [VOLUNTEER_DISCONNECTED]: {
          target: phoneBankEnded, // self transition
        },
      },
    },
    [phoneBankNotStarted]: {
      on: {
        [END_SESSION]: {
          target: sessionEnded,
        },
        [EXIT_ACTIVITY]: {
          target: dialingVolunteer,
        },
        [PHONE_BANK_STARTED]: {
          target: idle,
        },
        [VOLUNTEER_DISCONNECTED]: {
          target: volunteerDisconnected,
        },
      },
    },
    [phoneBankPaused]: {
      on: {
        [END_SESSION]: {
          target: sessionEnded,
        },
        [EXIT_ACTIVITY]: {
          target: dialingVolunteer,
        },
        [PHONE_BANK_STARTED]: {
          target: idle,
        },
        [VOLUNTEER_DISCONNECTED]: {
          target: volunteerDisconnected,
        },
      },
    },
    [sessionEnded]: {
      on: {
        [EXIT_ACTIVITY]: {
          target: dialingVolunteer,
        },
        [VOLUNTEER_DISCONNECTED]: {
          target: sessionEnded, // self transition
        },
      },
    },
    [sessionStats]: {
      on: {
        [EXIT_ACTIVITY]: {
          target: dialingVolunteer,
        },
        [SESSION_STATS_VIEWED]: {
          target: idle,
        },
        [SESSION_STATS_VIEWED_AUTO_CONNECT]: {
          target: autoConnectLoader,
        },
        [VOLUNTEER_DISCONNECTED]: {
          target: sessionStats, // self transition
        },
        [VOLUNTEER_HANG_UP]: {
          target: sessionStats, // self transition
        },
        [VOTER_HANG_UP]: {
          target: sessionStats, // self transition
        },
      },
    },
    [tagsAndCustomFields]: {
      on: {
        [EXIT_ACTIVITY]: {
          target: dialingVolunteer,
        },
        [NO_TAGS_OR_CUSTOM_FIELDS]: {
          target: sessionStats,
        },
        [TAGS_AND_CUSTOM_FIELDS_SUBMITTED]: {
          target: sessionStats,
        },
        [VOLUNTEER_DISCONNECTED]: {
          target: tagsAndCustomFields, // self transition
        },
        [VOLUNTEER_HANG_UP]: {
          target: tagsAndCustomFields, // self transition
        },
        [VOTER_HANG_UP]: {
          target: tagsAndCustomFields, // self transition
        },
      },
    },
    [viewResponses]: {
      on: {
        [EXIT_ACTIVITY]: {
          target: dialingVolunteer,
        },
        [RESPONSES_SUBMITTED]: {
          target: tagsAndCustomFields,
        },
      },
    },
    [volunteerDisconnected]: {
      on: {
        [EXIT_ACTIVITY]: {
          target: dialingVolunteer,
        },
        [PHONE_BANK_STARTED]: {
          target: volunteerDisconnected, // self transition
        },
        [RECONNECTED]: {
          target: idle,
        },
        [VOLUNTEER_DISCONNECTED]: {
          target: volunteerDisconnected, // self transition
        },
      },
    },
    [voterConnected]: {
      on: {
        [EXIT_ACTIVITY]: {
          target: dialingVolunteer,
        },
        [PATCH_THROUGH_CONNECTED]: {
          target: patchThroughConnected,
        },
        // We need this transition because a voter can be connected prior to
        // the app receiving the response back from the QUEUE_NEXT_CALL network
        // request.
        [QUEUE_NEXT_CALL]: {
          target: voterConnected, // self transition
        },
        [VOLUNTEER_DISCONNECTED]: {
          target: volunteerDisconnected,
        },
        [VOLUNTEER_HANG_UP]: {
          target: callOutcome,
        },
        [VOTER_HANG_UP]: {
          target: callOutcome,
        },
      },
    },
  },
});

const { addBreadcrumb, sendError, service } = machineDebugger(dialerMachine);

// helper function to build stack and report errors;
export const checkTransition = (state, action, machineState) => {
  // console.log('MACHINE LOG: ', { state, action, machineState })

  // Send to service for debugging in separate window
  if (service) {
    service.send(action.payload);
  }

  const approvedTransition = machineState.nextEvents.includes(action.payload);
  const message = approvedTransition
    ? `Dialer - Transition: ${action.payload} from ${state.currentState}`
    : `Dialer - Invalid Transition: ${action.payload} from ${state.currentState}`;

  // Add a general logging breadcrumb
  addBreadcrumb({
    category: 'Dialer',
    level: approvedTransition ? 'log' : 'warning',
    message,
  });

  // Check for an unhandled transition and report to Sentry
  if (!approvedTransition) {
    sendError(message);
  }
};

export default dialerMachine;
