import values from 'lodash/values';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useDocumentTitle, useComparePrevious, useMedia } from '../../hooks';
import { toObject, usePaginatedApi } from '../../hooks/usePaginatedApi';
import { track } from '../../services/analytics';
import { users } from '../../services/api';
import { media, sizes } from '../../styles/theme';
import InboxList from './InboxList';
import MessageThread from './MessageThread';
import { FilterTypes } from './consts';
import InboxHeader from './InboxHeader';
import Sidebar from '../AppContainer/Sidebar';

const getPaginatedApiParams = filter => {
  switch (filter) {
    case FilterTypes.UNREAD:
      return { unread: true };
    case FilterTypes.FRIENDS:
      return { friends: true };
    case FilterTypes.NEEDS_ATTENTION:
      return { needs_attention: true };
    case FilterTypes.TEAM:
      return { team: true };
    case FilterTypes.CANVASSED:
      return { canvassed: true };
    case FilterTypes.ARCHIVED:
      return { archived: true };
    default:
      return { unread: false };
  }
};

const InboxContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 7fr;
  margin-left: 80px;
`;

const HeaderAndInbox = styled.div`
  width: 100%;
  ${media.lg} {
    border-right: 1px solid ${({ theme }) => theme.colors.borderGray};
  }
`;

const MessageThreadsList = styled.div`
  overflow: auto;
  height: calc(100vh - 179px);
`;

const MessageThreadContainer = styled.div``;

const NotClickedOnMessageYet = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 16px;
`;

const InboxWrapper = styled.div`
  display: flex;
`;

const Inbox = () => {
  const { t } = useTranslation();
  const { selectedCampaign } = useSelector(state => state.oneCampaign);

  const { search } = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(search);
  const filterQueryParam = queryParams.get('filter') || undefined;
  const initialThreadId = queryParams.get('threadId') || undefined;
  const [filter, setFilter] = useState(filterQueryParam);

  const computedFilter = useMemo(() => {
    const validFilterValues = values(FilterTypes);
    return validFilterValues.includes(filter) ? filter : undefined;
  }, [filter]);

  const {
    hasMore,
    items: messages,
    loadMore,
    loading,
    initialLoading,
    resetPagination,
    cancelPendingRequests,
    restartIntervalRefetch,
    resetLiveUpdatePagination,
  } = toObject(
    usePaginatedApi(
      users.getUserMessageThreads,
      null,
      {
        ...getPaginatedApiParams(computedFilter),
        ...{ campaign_id: selectedCampaign?.id },
      },
      true,
    ),
  );

  useEffect(() => {
    if (selectedCampaign?.id) {
      resetPagination({ refetch: true });
    }
    // Do not change this as it will cause queries to loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCampaign?.id]);

  const small = useMedia(`(max-width: ${sizes.lg})`);

  let { messageThreadId: initialMessageThreadId = null } = useParams();

  const [messageThreadId, setMessageThreadId] = useState(initialMessageThreadId);

  const showMessageThread = !!messageThreadId;

  const setMessageThreadParams = messageThreadId => {
    restartIntervalRefetch(true);
    setMessageThreadId(messageThreadId);
  };

  useDocumentTitle(t('titles.inbox'));

  // Track the page view
  useEffect(() => {
    track('VIEW_INBOX_SCREEN');
    if (initialThreadId) setMessageThreadParams(initialThreadId);

    // Do not change to avoid errors
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialThreadId]);

  // Initial load
  const initialLoadingChanged = useComparePrevious(initialLoading);
  useEffect(() => {
    if (initialLoading && initialLoadingChanged) {
      loadMore();
    }
  });

  const handleRefresh = useCallback(() => {
    resetPagination();
    cancelPendingRequests();
  }, [resetPagination, cancelPendingRequests]);

  const handleFilterChange = useCallback(
    newFilter => {
      if (computedFilter === newFilter) {
        return;
      }
      setFilter(newFilter);
      history.push(newFilter ? `?filter=${newFilter}` : '?');
      handleRefresh();
    },
    [computedFilter, setFilter, history, handleRefresh],
  );

  // React to external query param changes (e.g. browser back button)
  useEffect(() => {
    if (filterQueryParam !== filter) {
      setFilter(filterQueryParam);
      handleRefresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQueryParam, handleRefresh]);

  const renderHeaderAndInbox = () => {
    return (
      <HeaderAndInbox>
        <InboxHeader
          filter={computedFilter}
          onFilterChange={handleFilterChange}
          refreshing={initialLoading}
          onRefresh={handleRefresh}
        />
        <MessageThreadsList>
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={loadMore}
            hasMore={!loading && hasMore}
            useWindow={false}
          >
            <InboxList
              messages={messages}
              loading={loading}
              messageThreadId={messageThreadId}
              setMessageThreadParams={setMessageThreadParams}
            />
          </InfiniteScroll>
        </MessageThreadsList>
      </HeaderAndInbox>
    );
  };

  const renderMessageThread = () => {
    return (
      <MessageThread
        key={messageThreadId}
        setMessageThreadParams={setMessageThreadParams}
        messageThreadId={messageThreadId}
        handleThreadsRefresh={handleRefresh}
        refetchThreads={resetLiveUpdatePagination}
      />
    );
  };

  const renderMessageThreadContainer = () => {
    return (
      <MessageThreadContainer>
        {!showMessageThread && (
          <NotClickedOnMessageYet>{t('inbox.click_on_a_message')}</NotClickedOnMessageYet>
        )}
        {showMessageThread && renderMessageThread()}
      </MessageThreadContainer>
    );
  };

  /* On smaller screens, only show the inbox or the messages one at a time */
  if (small) {
    // show inbox if message thread isn't set
    if (!showMessageThread) {
      return renderHeaderAndInbox();
    }
    return <>{renderMessageThreadContainer()}</>;
  }

  return (
    <InboxWrapper>
      <Sidebar mini />
      <InboxContainer>
        {renderHeaderAndInbox()}
        {renderMessageThreadContainer()}
      </InboxContainer>
    </InboxWrapper>
  );
};

Inbox.propTypes = {};

export default Inbox;
