import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import PhoneSecondary from '../../icons/PhoneSecondary';
import AnswerBlock from './AnswerBlock';
import AnswerItem from './AnswerItem';

import useInterpolation from '@web/components//DialerActivity/useInterpolation';

const StyledScriptBlock = styled.div`
  margin-bottom: 24px;
  width: 100%;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-family: ${({ theme }) => theme.fonts.light};
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 22px;
`;

const TextSection = styled.div``;

const PatchThroughItem = styled(AnswerItem)`
  display: flex;
  align-items: center;
  gap: 10px;
  background: ${({ theme }) => theme.colors.coral};
  &:disabled {
    background: rgb(124, 122, 124);
    border-color: rgb(124, 122, 124) !important;
    color: white !important;
    opacity: 1;
  }
`;

function ScriptBlock({
  answersDisabled,
  connectedContact,
  onAnswerSelect,
  options,
  selectedAnswerRef,
  stem,
  ...rest
}) {
  const { t } = useTranslation();
  const { interpolated } = useInterpolation(stem, connectedContact);
  const hasOptions = options?.length > 0;

  if (!options) {
    return null;
  }

  const paragraphs = interpolated.split('\n').filter(el => el !== '');

  return (
    <StyledScriptBlock>
      <TextSection>
        {paragraphs.map((el, i) => (
          <Text key={i} dangerouslySetInnerHTML={{ __html: el }} />
        ))}
      </TextSection>
      <AnswerBlock>
        {options.map(option => {
          if (option.patch_through) {
            const isDisabled = answersDisabled.includes(option.reference);
            return (
              <PatchThroughItem
                disabled={isDisabled}
                key={option.reference}
                onPress={() => onAnswerSelect(option)}
                selected={selectedAnswerRef === option.reference}
              >
                <PhoneSecondary />
                {isDisabled ? t('activity.phone_bank.patch_through.loading') : option.label}
              </PatchThroughItem>
            );
          }

          return (
            <AnswerItem
              key={option.reference}
              onPress={() => onAnswerSelect(option)}
              selected={selectedAnswerRef === option.reference}
            >
              {option.label}
            </AnswerItem>
          );
        })}
        {!hasOptions && (
          <Text style={{ marginTop: 16, textAlign: 'center' }}>No Response Needed</Text>
        )}
      </AnswerBlock>
    </StyledScriptBlock>
  );
}

ScriptBlock.propTypes = {
  answersDisabled: PropTypes.bool,
  connectedContact: PropTypes.object.isRequired,
  onAnswerSelect: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.any),
  selectedAnswerRef: PropTypes.string,
  stem: PropTypes.string.isRequired,
};

ScriptBlock.defaultProps = {
  answersDisabled: false,
  onAnswerSelect: () => null,
  selectedAnswerRef: null,
};

export default ScriptBlock;
