import * as React from 'react';
import PropTypes from 'prop-types';
import TextWithIcon from '../TextWithIcon';
import StatusBar from '../StatusBar';
import styled from 'styled-components';
import Contact from '../Contact';
import TagsAndCustomFields from '../../../TagsAndCustomFields';
import { useTranslation } from 'react-i18next';

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 140px);
  overflow: auto;
`;

const PatchedThroughCall = ({ activity }) => {
  const { t } = useTranslation();
  return (
    <>
      <StatusBar bgColor="teal">
        <TextWithIcon>{t('activity.phone_bank.patch_through.banner_title')}</TextWithIcon>
      </StatusBar>
      <Content>
        <Contact activity={activity} />
        <TagsAndCustomFields activity={activity} reportDuringCall />
      </Content>
    </>
  );
};

PatchedThroughCall.propTypes = {
  activity: PropTypes.object.isRequired,
};

export default PatchedThroughCall;
