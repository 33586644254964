import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { usePaginatedApi, toObject } from '../../hooks/usePaginatedApi';
import { user } from '../../services/api';
import PinnedActivityCarousel from '../Activity/PinnedActivityCarousel';
import RecentlyParticipatedActionsCarousel from '../Activity/RecentlyParticipatedActionsCarousel';
import ActivityFilters from '../common/ActivityFilters';
import ActivityList from './ActivityList';

import ListEmpty from './ListEmpty';
import { useSelector } from 'react-redux';
import { AuthContext } from '@web/utils/context';

const StyledListController = styled.div`
  margin-bottom: 3rem;
`;

const ListWrapper = styled.div`
  & + & {
    margin-top: 2rem;
  }
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.semibold};
  font-size: 2rem;
`;

function ListController({ charityList, ...rest }) {
  const { user: userData } = useContext(AuthContext);
  const campaign = useSelector(state => state.oneCampaign.selectedCampaign);
  const { t } = useTranslation();
  // Activity filters
  const [filters, setFilters] = useState({
    activity_type: undefined,
    cause: undefined,
    zip: undefined,
  });

  const [pinnedActions, setPinnedActions] = useState(null);

  const loadPinnedActions = useCallback(() => {
    user
      .getUserActivities({
        campaign_id: campaign?.id,
        pinned: true,
      })
      .then(({ data: { data } }) => setPinnedActions(data));
  }, [campaign.id]);

  // Handle initial data fetching
  useEffect(() => {
    loadPinnedActions();
    // eslint-disable-next-line
  }, [loadPinnedActions]);

  // Activity paginated data
  const { hasMore, initialLoading, items, loadMore, loading, resetPagination } = toObject(
    usePaginatedApi(user.getUserActivities, null, {
      campaign_id: campaign?.id,
      ...filters,
    }),
  );

  useEffect(() => {
    resetPagination({ refetch: true });
    // Do not change this as it will cause queries to loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign.id]);

  const filteredItems = useMemo(
    () =>
      (
        items?.filter(
          item => !(item?.activity?.aasm_state && item?.activity?.aasm_state === 'draft'),
        ) || []
      )
        // If a specific language is selected for an activity, we need to check if it matches the language selected by the user
        .filter(item => !item.activity?.locale || item.activity?.locale === userData?.locale),
    [userData?.locale, items],
  );

  const onlyActionItems = useMemo(
    () =>
      filteredItems.filter(
        item =>
          ![
            'UserBroadcastActivity',
            'IdVotersActivity',
            'AssignedCanvassingActivity',
            'RegistrationActivity',
          ].includes(item?.activity?.type),
      ),
    [filteredItems],
  );
  const recentlyParticipatedItems = onlyActionItems.filter(item => item.completed);

  const renderPinned = pinnedActions?.length > 0;
  const renderLatest = items?.length > 0;
  const renderRecentlyParticipated = recentlyParticipatedItems.length > 0;

  const onFilterChangeHandler = useCallback(
    newFilters => {
      if (!isEqual(filters, newFilters)) {
        setFilters(newFilters);
        resetPagination({ refetch: true });
      }
    },
    [filters, resetPagination],
  );

  return (
    <>
      <StyledListController {...rest}>
        {renderRecentlyParticipated && (
          <ListWrapper>
            <Title>{t('frontline.participated_actions')}</Title>
            <RecentlyParticipatedActionsCarousel activities={recentlyParticipatedItems} />
          </ListWrapper>
        )}
        {renderPinned && (
          <ListWrapper>
            <Title>{t('frontline.featured_actions')}</Title>
            <PinnedActivityCarousel activities={pinnedActions} />
          </ListWrapper>
        )}
        <ListWrapper>
          <Title>{t('frontline.latest_actions')}</Title>
          <ActivityFilters onChange={onFilterChangeHandler} />
          {renderLatest && (
            <ActivityList
              hasMore={hasMore}
              initialLoading={initialLoading}
              items={onlyActionItems}
              loadMore={loadMore}
              loading={loading}
            />
          )}
        </ListWrapper>
        {!renderPinned && !renderLatest && <ListEmpty />}
      </StyledListController>
    </>
  );
}

ListController.propTypes = {
  charityList: PropTypes.bool,
};

ListController.defaultProps = {
  charityList: false,
};

export default ListController;
