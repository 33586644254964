import * as React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';
import ReactInputMask from 'react-input-mask';
import styled from 'styled-components';

const StyledInput = styled(Input)`
  .ant-input-group-addon {
    > span {
      margin-bottom: 8px;
    }
  }
`;

function PhoneInput({ phoneNumber, setPhoneNumber, ...rest }) {
  const onPhoneChangeHandler = React.useCallback(
    ({ target: { value: number } }) => setPhoneNumber(number),
    [setPhoneNumber],
  );

  return (
    <Form.Item style={{ maxWidth: 387 }}>
      <ReactInputMask
        onChange={onPhoneChangeHandler}
        value={phoneNumber}
        mask={'(999) 999-9999'}
        {...rest}
      >
        {inputProps => (
          <StyledInput
            addonBefore={<PhoneOutlined />}
            placeholder={'Phone Number'}
            {...inputProps}
          />
        )}
      </ReactInputMask>
    </Form.Item>
  );
}

PhoneInput.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  setPhoneNumber: PropTypes.func.isRequired,
};

PhoneInput.defaultProps = {};

export default PhoneInput;
