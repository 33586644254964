import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@web/styles/theme';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 130px auto;
  grid-template-rows: auto;
  grid-template-areas:
    'image titles'
    'buttons buttons';
  gap: 30px;
`;

const ProfileImage = styled.img`
  width: 130px;
  height: 130px;
  border-radius: 100%;
  grid-area: image;
  border: solid ${theme.colors.trueBlack} 2px;
  object-fit: cover;
`;

const Titles = styled.div`
  grid-area: titles;
  font-size: 36px;
  color: ${theme.colors.trueBlack};
  font-weight: 700;
  align-self: center;
`;

const TopSection = ({ controls }) => {
  return (
    <Wrapper>
      <ProfileImage src={controls.campaign?.url_profile_img} alt="Campaign logo" />
      <Titles>
        <div>Leaderboard:</div>
        <div>{controls.activity?.title}</div>
      </Titles>
    </Wrapper>
  );
};

TopSection.propTypes = {
  controls: PropTypes.shape({
    activity: PropTypes.shape({
      title: PropTypes.string,
    }),
    campaign: PropTypes.shape({
      url_profile_img: PropTypes.string,
    }),
  }),
};

export default TopSection;
