import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ImpactiveButton from '@web/components/ImpactiveButton';

const HeaderMedia = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2em;
`;

const DescriptionText = styled.div`
  display: flex;
  white-space: pre-wrap;
  margin-bottom: 2em;
  text-align: center;
`;

const Instructions = ({ campaign, onInstructionsFinish }) => {
  const { t } = useTranslation();

  return (
    <>
      <HeaderMedia>
        <h1>{t('campaign.onboarding.welcome')}</h1>
        {campaign?.instructions_video_url && (
          <video controls width="100%" height="320">
            <source src={campaign.instructions_video_url} />
            <p>
              {t('campaign.onboarding.video_not_supported')}
              <a href={campaign.instructions_video_url}>{t('common.link')}</a>
            </p>
          </video>
        )}
      </HeaderMedia>

      {campaign?.instructions_text && (
        <DescriptionText>{campaign?.instructions_text}</DescriptionText>
      )}

      <ImpactiveButton size="large" onClick={onInstructionsFinish}>
        {t('common.continue')}
      </ImpactiveButton>
    </>
  );
};

Instructions.propTypes = {
  campaign: PropTypes.object,
  onInstructionsFinish: PropTypes.func.isRequired,
};

export default Instructions;
