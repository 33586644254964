import * as React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledMessage = styled.div`
  background-color: #d5ecef;
  border-radius: 7px;
  margin-bottom: 16px;
  margin-top: 16px;
  padding: 22px;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  text-align: center;
`;

const HelpMessage = ({ helpMessageKey = 'activity.phone_bank.script_help' }) => {
  const { t } = useTranslation();
  return (
    <StyledMessage>
      <Text>{t(helpMessageKey)}</Text>
    </StyledMessage>
  );
};

HelpMessage.propTypes = {
  helpMessageKey: PropTypes.string,
};

export default HelpMessage;
