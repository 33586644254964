import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as yup from 'yup';
import { Formik, Form } from 'formik';

import ImpactiveButton from '../../ImpactiveButton';
import { setAppLanguage } from '../../i18n';
import i18n from '@web/components/i18n';
import PhoneNumberInput from '@web/components/common/shared/PhoneNumberInput';
import FormInput from '@web/components/common/shared/FormInput';
import ImpactiveSelect from '@admin/components/helpers/ImpactiveSelect';
import theme from '@web/styles/theme';

const { t: translate } = i18n;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const StyledImpactiveSelect = styled(ImpactiveSelect)`
  &&& {
    .ant-select-selector {
      border-radius: 6px;
      background-color: ${theme.colors.alabaster};
    }
  }
`;

const statuses = [
  { label: 'English', value: 'en' },
  { label: 'Español', value: 'es' },
  { label: '한국어', value: 'ko' },
];

const validationSchema = yup.object().shape({
  first_name: yup.string().nullable().required(translate('auth.complete_auth.errors.first_name')),
  last_name: yup.string().nullable().required(translate('auth.complete_auth.errors.last_name')),
  locale: yup.string().required('Language is required'),
  phone: yup
    .string()
    .nullable()
    .matches(/^(\1)?[0-9]{11}$/, translate('auth.complete_auth.errors.invalid_phone'))
    .required(translate('auth.complete_auth.errors.phone')),
  zip: yup
    .string()
    .nullable()
    .matches(/^\d{5}$/, translate('auth.complete_auth.errors.invalid_zip'))
    .required(translate('auth.complete_auth.errors.zip')),
});

const UserDetails = ({ onSubmit, user }) => {
  const { t } = useTranslation();

  const initialValues = useMemo(
    () => ({
      first_name: user?.first_name,
      last_name: user?.last_name,
      locale: user?.locale || 'en',
      phone: user?.phone,
      zip: user?.supplied_zip_code,
    }),
    [user],
  );

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      onSubmit(values, setSubmitting);
    },
    [onSubmit],
  );

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}
      render={({ values, isSubmitting, handleChange, setFieldValue, errors, touched }) => {
        return (
          <StyledForm>
            <div className="h3 text-center">{t('campaign.onboarding.welcome')}</div>

            <div className="text-center">{t('campaign.onboarding.know_better')}</div>

            <FormInput
              name="first_name"
              label={t('placeholders.first_name')}
              placeholder={t('placeholders.first_name')}
              withoutLabel
              value={values.first_name}
              onChange={handleChange}
            />

            <FormInput
              name="last_name"
              label={t('placeholders.last_name')}
              placeholder={t('placeholders.last_name')}
              withoutLabel
              value={values.last_name}
              onChange={handleChange}
            />

            <PhoneNumberInput
              name="phone"
              label={t('placeholders.phone_number')}
              placeholder={t('placeholders.phone_number')}
              isRequired
              withoutLabel
              value={values.phone}
              onChange={e => setFieldValue('phone', e)}
            />

            <FormInput
              name="zip"
              formik={{ errors, touched, values }}
              label={t('placeholders.zip')}
              placeholder={t('placeholders.zip')}
              withoutLabel
              value={values.zip}
              onChange={handleChange}
              maxLength={5}
            />

            <StyledImpactiveSelect
              name="locale"
              placeholder="Language"
              options={statuses}
              allowClear={false}
              onChange={e => {
                setFieldValue('locale', e);
                setAppLanguage(e);
              }}
              value={values.locale}
            />

            <div className="d-flex flex-row align-items-center justify-content-center text-center">
              <ImpactiveButton size="large" htmlType="submit" disabled={isSubmitting}>
                {t('common.continue')}
              </ImpactiveButton>
            </div>
          </StyledForm>
        );
      }}
    />
  );
};

UserDetails.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.object,
};

export default UserDetails;
