import { Input, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { track } from '../../../services/analytics';
import { isValidEmail } from '../../../utils/string';
import ImpactiveButton from '../../ImpactiveButton';

const PasswordlessAuth = ({ onSubmitClick, buttonText, isSubmitting, setIsSubmitting }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');

  // Track the page view
  useEffect(() => {
    track('VIEW_PASSWORDLESS_INPUT_SCREEN');
  }, []);

  const hasErrors = () => {
    // validate input, only submit for valid email addresses
    if (!isValidEmail(email)) {
      message.error(t('auth.email.invalid'));
      setIsSubmitting(false);
      return true;
    }

    return false;
  };

  const handleLogin = e => {
    track('CLICK_LOGIN');
    setIsSubmitting(true);
    e.preventDefault();

    if (hasErrors()) {
      return;
    }
    onSubmitClick(email);
  };

  return (
    <form
      data-testid="auth-form"
      onSubmit={handleLogin}
      className="flex-row flex-column align-items-center"
    >
      <Input
        autoFocus
        type="email"
        name="email"
        placeholder={t('placeholders.email')}
        className="mb-3"
        onChange={e => setEmail(e.target.value)}
      />

      <ImpactiveButton htmlType="submit" size="large" disabled={isSubmitting}>
        {buttonText}
      </ImpactiveButton>
    </form>
  );
};

PasswordlessAuth.propTypes = {
  buttonText: PropTypes.string,
  isSubmitting: PropTypes.bool,
  onSubmitClick: PropTypes.func.isRequired,
  setIsSubmitting: PropTypes.func.isRequired,
};

PasswordlessAuth.defaultProps = {
  buttonText: 'Continue',
  includeFooter: true,
};

export default PasswordlessAuth;
