import * as React from 'react';
import PropTypes from 'prop-types';

import CallOutcome from '../CallOutcome';
import InCallTabs from '../InCallTabs';
import Loading from '@web/components/common/Loading';
import SessionEnded from '../SessionEnded';
import SessionStats from '../SessionStats';
import TagsAndCustomFields from '../TagsAndCustomFields';
import ViewResponses from '../ViewResponses';
import SurveyPatchThroughSuccess from '../SurveyPatchThroughSuccess/SurveyPatchThroughSuccess';

import useAnswers from './useAnswers';
import { useSelector } from 'react-redux';

function MainContent({ activity }) {
  const { currentState } = useSelector(state => state.dialer);
  const interactiveScriptProps = useAnswers(activity);

  if (interactiveScriptProps.loading) {
    return <Loading />;
  }

  switch (currentState) {
    case 'callOutcome':
      return <CallOutcome activity={activity} />;
    case 'sessionStats':
      return <SessionStats activity={activity} />;
    case 'sessionEnded':
      return <SessionEnded />;
    case 'tagsAndCustomFields':
      return <TagsAndCustomFields activity={activity} />;
    case 'viewResponses':
      return <ViewResponses interactiveScriptProps={interactiveScriptProps} />;
    case 'patchThroughSuccess':
      return <SurveyPatchThroughSuccess />;
    case 'patchThroughConnected':
      return (
        <InCallTabs
          activity={activity}
          interactiveScriptProps={interactiveScriptProps}
          isPatchThroughConnected
        />
      );
    default:
      return <InCallTabs activity={activity} interactiveScriptProps={interactiveScriptProps} />;
  }
}

MainContent.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

MainContent.defaultProps = {};

export default MainContent;
