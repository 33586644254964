import React, { useCallback, useContext, useRef, useState } from 'react';
import ImpactiveModal from '@web/components/common/ImpactiveModal';
import VerificationInput from 'react-verification-input';
import { useTranslation } from 'react-i18next';
import ImpactiveButton from '@web/components/ImpactiveButton';
import { message } from 'antd';
import { campaigns, users } from '@web/services/api';
import { AuthContext } from '@web/utils/context';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getLocalizedOnboardingData } from '@web/utils/other';
import { setCampaign, setJoinedCampaigns } from '@web/reducers/oneCampaign';
import PropTypes from 'prop-types';
import { teamJoinCodeError } from '@web/utils/errorHandler';
import usersApi from '@web/services/api/users';

// This modal is short representation of app/javascript/web/components/Auth/Join Campaign.js file
// In the JoinCampaign.js we have more logic because we have to check if user logged in/authorized and a lot of other different conditions
// I decided to create this modal instead of passing arguments to JoinCampaign component and conditionally do different things to avoid bugs
const JoinCampaignModal = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const inputRef = useRef();

  const [code, setCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { setCampaignSlug, setUser } = useContext(AuthContext);

  const onChange = useCallback(value => {
    setCode(value);
  }, []);

  const onComplete = useCallback(value => {
    setCode(value);
    inputRef.current?.blur();
  }, []);

  const onFocus = useCallback(() => {
    if (code?.length === 6) {
      setCode(code.slice(0, 5));
    }
  }, [code]);

  const getJoinedCampaigns = useCallback(() => {
    usersApi.getUserCampaigns(1, 5000).then(({ data: { data: campaigns } }) => {
      // Save campaigns in redux
      dispatch(setJoinedCampaigns(campaigns));
    });
  }, [dispatch]);

  const joinCampaign = useCallback(
    async campaignFromCode => {
      users.followCampaign({
        campaign_id: campaignFromCode.id,
        campaign_name: campaignFromCode.name,
        campaign_slug: campaignFromCode.slug,
      });

      setCampaignSlug(campaignFromCode.slug);
      setIsSubmitting(false);

      users.updateMe({ last_campaign_id: campaignFromCode.id }).then(({ data: { data } }) => {
        const localizedCampaign = getLocalizedOnboardingData(campaignFromCode);
        dispatch(setCampaign(localizedCampaign));
        setUser(data);
        onClose();
        getJoinedCampaigns();
        history.push(`/campaigns/${campaignFromCode.slug}?showOnboarding=true`);
      });
    },
    [dispatch, history, setCampaignSlug, setUser, onClose, getJoinedCampaigns],
  );

  const onSubmit = useCallback(() => {
    if (code?.length !== 6) {
      message.error(t('auth.join.invalid_code'));
    } else {
      setIsSubmitting(true);
      campaigns
        .findCampaign(code)
        .then(({ data }) => {
          if (!data) {
            setIsSubmitting(false);
            message.error(t('explore.join_code.error'));
          } else {
            // success
            joinCampaign(data.data);
          }
        })
        .catch(error => {
          setIsSubmitting(false);
          teamJoinCodeError(error);
        });
    }
  }, [code, t, joinCampaign]);

  return (
    <ImpactiveModal visible onCancel={onClose}>
      <div className={`card-title bolder mb-4`}>{t('auth.join.header')}</div>

      <div className="text-center">{t('auth.join.enter')}</div>
      <div className="text-center">{t('auth.join.dont_have_code')}</div>

      <VerificationInput
        ref={inputRef}
        value={code}
        classNames={{
          character: 'character',
          characterFilled: 'character--filled',
          characterInactive: 'character--inactive',
          characterSelected: 'character--selected',
          container: 'container',
        }}
        inputProps={{ disabled: isSubmitting, inputMode: 'numeric' }}
        validChars="0-9"
        placeholder="__"
        autoFocus
        length={6}
        onChange={onChange}
        onFocus={onFocus}
        onComplete={onComplete}
      />
      <div className="d-flex flex-row align-items-center justify-content-center mt-5 text-center">
        <ImpactiveButton size="large" disabled={isSubmitting} onClick={onSubmit}>
          {isSubmitting ? t('auth.join.joining') : t('common.submit')}
        </ImpactiveButton>
      </div>
    </ImpactiveModal>
  );
};

JoinCampaignModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default JoinCampaignModal;
