import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BackButton from '../../components/BackButton';
import NextButton from '../../components/NextButton';
import Page, { PageCopy } from '../../components/Page';
import SuccessIcon from '@web/components/DialerActivity/icons/Success';
import { Title } from '@web/components/DialerActivity/components/TextStyles';

import { machineTransition } from '@web/reducers/dialerReducer';
import { VOLUNTEER_ANSWERED } from '@web/reducers/dialerReducer/dialerMachine';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const StyledPageCopy = styled(PageCopy)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -60px;
`;

function Success({ onBack, onNext }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <Page>
      <StyledPageCopy>
        <SuccessIcon size={118} />
        <Title>{t('activity.phone_bank.you_are_in')}</Title>
      </StyledPageCopy>
      <NextButton onClick={() => dispatch(machineTransition(VOLUNTEER_ANSWERED))}>
        {t('activity.phone_bank.you_are_in_button_text')}
      </NextButton>
      <BackButton onClick={onBack}>{t('common.cancel')}</BackButton>
    </Page>
  );
}

Success.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

Success.defaultProps = {};

export default Success;
