import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { message } from 'antd';

import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { users } from '../../services/api';
import { AuthContext } from '../../utils/context';
import { isAuthFinished } from '@web/utils/other';
import { getAndClearRedirectAfterLoginURL } from 'web/services/session';
import Loading from '../common/Loading';
import ImpactiveOrCampaignLogo from './Components/ImpactiveOrCampaignLogo';
import PoweredByImpactive from './Components/PoweredByImpactive';
import LanguageSelect from './LanguageSelect';
import CompleteAuthForm from './Components/CompleteAuthForm';

const CompleteAuth = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { campaign_slug } = useParams();

  const title = campaign_slug ? t('auth.sign_up') : t('auth.login');
  useDocumentTitle(title);

  const { user, onboardingData, campaign, isLoading, setCampaignSlug, setUser } =
    useContext(AuthContext);

  useEffect(() => {
    // wait for all the data to be loaded
    if (isLoading) {
      return;
    }

    if (!user) {
      history.goBack();
    } else {
      if (isAuthFinished(user)) {
        const redirectURL = getAndClearRedirectAfterLoginURL();

        history.push(redirectURL || '/frontline');
      }
    }
  }, [campaign_slug, setCampaignSlug, user, isLoading, history]);

  const onSubmit = useCallback(
    (values, setSubmitting) => {
      setSubmitting(true);
      const { first_name, last_name, phone, zip } = values;

      users
        .updateMe({ first_name, last_name, phone: `+${phone}`, supplied_zip_code: zip })
        .then(({ data: { data } }) => {
          setUser(data);
          const redirectURL = getAndClearRedirectAfterLoginURL();

          history.push(redirectURL || '/frontline');
        })
        .catch(error => {
          message.error(
            error?.response?.data?.message || error?.message || t('common.something_went_wrong'),
          );
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [setUser, history, t],
  );

  if (isLoading) {
    return (
      <div className="d-flex flex-row justify-content-center">
        <Loading />
      </div>
    );
  }

  if (!onboardingData.language) {
    return <LanguageSelect />;
  }

  return (
    <div
      className={`h-100 bg-auth-cyan d-flex flex-column align-items-center justify-content-center`}
    >
      <ImpactiveOrCampaignLogo campaign={campaign} />

      <div className="card fixed-auth">
        <div className={`card-title bolder mb-4`}>{t('auth.complete_auth.header')}</div>

        <CompleteAuthForm onSubmit={onSubmit} />
      </div>

      {campaign && <PoweredByImpactive />}
    </div>
  );
};

CompleteAuth.propTypes = {};

export default CompleteAuth;
