import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CustomFieldsList from '@web/components/common/CustomFieldsList';
import ImpactiveButton from '@web/components/ImpactiveButton';

const CustomFields = ({ campaign, CFs, setCFs, handleSubmitReport }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="d-flex flex-column align-items-center">
        <h1>{t('campaign.onboarding.welcome')}</h1>
        <p>{t('campaign.onboarding.answer_questions', { name: campaign?.name })}</p>
      </div>
      <CustomFieldsList
        customizations={CFs}
        setUserReportCustomizations={updatedCustomFields => setCFs(updatedCustomFields)}
      />
      <ImpactiveButton size="large" onClick={handleSubmitReport}>
        {t('common.continue')}
      </ImpactiveButton>
    </>
  );
};

CustomFields.propTypes = {
  campaign: PropTypes.object,
  CFs: PropTypes.array.isRequired,
  handleSubmitReport: PropTypes.func.isRequired,
  setCFs: PropTypes.func.isRequired,
};

export default CustomFields;
