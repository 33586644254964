import * as React from 'react';
import PropTypes from 'prop-types';

import HelpMessage from '@web/components/DialerActivity/components/HelpMessage';
import Instructions from '@web/components/DialerActivity/components/Instructions';
import InteractiveScript from '../InteractiveScript';
import Tabs from '@web/components/DialerActivity/components/Tabs';

import { AuthContext } from '@web/utils/context';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function InCallTabs({ activity, interactiveScriptProps, isPatchThroughConnected }) {
  const { connectedContact } = useSelector(state => state.dialer);
  const { user } = React.useContext(AuthContext);
  const simulatedContact = { campaign: activity.campaign, user };
  const [simulatedAnswers, setSimulatedAnswers] = React.useState([]);
  const { loading } = interactiveScriptProps;
  const { t } = useTranslation();

  // Reset the preview script once a contact has connected
  React.useEffect(() => {
    setSimulatedAnswers([]);
  }, [connectedContact]);

  function getScriptComponent() {
    if (loading || !interactiveScriptProps?.questions?.length) {
      return null;
    }

    if (connectedContact) {
      return (
        <InteractiveScript
          connectedContact={connectedContact}
          {...interactiveScriptProps}
          isPatchThroughConnected={isPatchThroughConnected}
        />
      );
    }

    // User is in an idle state and we don't have a contact connected so we need
    // to override answers and setAnswers so that we're not changing the values
    // in Redux.
    const simulatedProps = {
      ...interactiveScriptProps,
      answers: simulatedAnswers,
      setAnswers: setSimulatedAnswers,
    };

    return (
      <>
        <HelpMessage />
        <InteractiveScript connectedContact={simulatedContact} {...simulatedProps} />
      </>
    );
  }

  const pages = [
    {
      content: getScriptComponent(),
      title: t('common.script'),
    },
    {
      content: (
        <>
          <HelpMessage helpMessageKey="activity.phone_bank.instructions_help" />
          <Instructions />
        </>
      ),
      title: t('common.instructions'),
    },
  ];
  return <Tabs pages={pages} />;
}

InCallTabs.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    description: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  }),
  interactiveScriptProps: PropTypes.shape({
    answers: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    questions: PropTypes.array.isRequired,
    setAnswers: PropTypes.func.isRequired,
  }).isRequired,
  isPatchThroughConnected: PropTypes.bool,
};

InCallTabs.defaultProps = {};

export default InCallTabs;
