import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ImpactiveLogo from './ImpactiveLogo';

import poweredBy from '../../../images/powered-by.png';

const PoweredByContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 1.875rem 0;
  padding: 0.875rem;

  @media (max-height: 850px) {
    margin: 0.5rem 0 0;
  }
  @media (max-height: 750px) {
    margin: 0;
  }
`;

const PoweredByLogo = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;

const PoweredByText = styled.div`
  text-transform: uppercase;
  font-style: italic;
  margin-right: 0.3rem;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.blue};
`;

const PoweredByImpactive = () => {
  const { t } = useTranslation();

  return (
    <PoweredByContainer>
      <PoweredByLogo src={poweredBy} alt="powered by" />
      <PoweredByText>{t('common.by')}</PoweredByText>
      <ImpactiveLogo isPoweredBy type="dark" />
    </PoweredByContainer>
  );
};

export default PoweredByImpactive;
