import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import Button from '@web/components/VoterRegistration/newComponents/Button';
import ContactForm from '@web/components/VoterRegistration/newComponents/ContactForm';
import { Description, Title } from '@web/components/VoterRegistration/newComponents/TextStyles';

import {
  registerToVote,
  fanOutRegisterToVote,
} from '@web/components/VoterRegistration/hooks/useFlows';
import { track } from '@web/services/analytics';
import useContactForm from '@web/components/VoterRegistration/hooks/useContactForm';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { amplitudeTrack } from '@web/components/FanOut/hooks/useFanOutAmplitude';
import { vrDefaultColors } from '@web/styles/theme';

const StyledButton = styled(Button)`
  text-decoration: ${({ isFanOut }) => (isFanOut ? 'underline' : 'none')};
`;

function FindRegistration(props) {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { referral_hash: referralHash } = queryString.parse(search);
  const { contactFormResult, formValues } = useSelector(state => state.voterRegistration);
  const { activity, team, isFanOut } = props;
  const { initialValues, schemas } = useContactForm(formValues ?? contactFormResult, activity);

  React.useEffect(() => {
    if (isFanOut) {
      amplitudeTrack({
        activity,
        name: referralHash
          ? 'FAN_OUT_VIEW_CHECK_REGISTRATION_FORM_REFERRED'
          : 'FAN_OUT_VIEW_CHECK_REGISTRATION_FORM',
        team,
      });
    } else {
      track('VR_VIEW_CHECK_REGISTRATION_FORM', {
        activity_id: activity?.id,
        activity_title: activity?.title,
        activity_type: activity?.type,
        campaign_id: activity?.campaign?.id,
        campaign_name: activity?.campaign?.name,
        team_id: team?.id,
        team_name: team?.name,
      });
    }
    // eslint-disable-next-line
  }, []);

  function handleNotRegisteredClick() {
    if (isFanOut) {
      props.setSelectedFlow(fanOutRegisterToVote);
    } else {
      props.setSelectedFlow(registerToVote);
    }
  }

  const fontColor = React.useMemo(
    () => (!isFanOut && activity?.settings?.font_color) || vrDefaultColors.fontColor,
    [isFanOut, activity?.settings?.font_color],
  );

  const secondaryButton = (
    <StyledButton
      onClick={handleNotRegisteredClick}
      customVariant={activity.settings}
      isFanOut={isFanOut}
      variant="link"
    >
      {isFanOut
        ? t('check_registration.not_registered_yet')
        : t('check_registration.not_registered')}
    </StyledButton>
  );
  return (
    <>
      <Title $fontColor={fontColor}>
        {isFanOut
          ? t('idvoters.findRegistration.fanOutTitle')
          : t('idvoters.findRegistration.title')}
      </Title>
      <Description $fontColor={fontColor}>
        {isFanOut
          ? referralHash
            ? t('idvoters.findRegistration.with_referral_description_fan_out')
            : t('idvoters.findRegistration.description_fan_out')
          : t('idvoters.findRegistration.description')}
      </Description>
      <ContactForm
        initialValues={initialValues.contactForm}
        schema={isFanOut ? schemas.fanOutContactForm : schemas.contactForm}
        secondaryButton={secondaryButton}
        {...props}
      />
    </>
  );
}

FindRegistration.propTypes = {
  activity: PropTypes.object.isRequired,
  isFanOut: PropTypes.bool,
  nextPage: PropTypes.func.isRequired,
  setSelectedFlow: PropTypes.func.isRequired,
  team: PropTypes.object,
};

FindRegistration.defaultProps = {};

export default FindRegistration;
