import PropTypes from 'prop-types';
import React, { useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { AuthContext } from '../../utils/context';
import ImpactiveButton from '../ImpactiveButton';
import Loading from '../common/Loading';
import ImpactiveOrCampaignLogo from './Components/ImpactiveOrCampaignLogo';
import PoweredByImpactive from './Components/PoweredByImpactive';
import LanguageSelect from './LanguageSelect';

const PasswordlessConfirmPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { campaign_slug } = useParams();
  const { onboardingData, campaign, isLoading, setCampaignSlug } = useContext(AuthContext);

  const title = campaign_slug ? t('titles.sign_up') : t('titles.sign_in');
  useDocumentTitle(title);

  const {
    location: { state },
  } = history;
  const email = state && state.email;

  useEffect(() => {
    setCampaignSlug(campaign_slug);
  }, [campaign_slug, setCampaignSlug]);

  const onResendClick = useCallback(() => {
    if (campaign_slug) {
      history.push(`/auth/signup/${campaign_slug}/passwordless`);
    } else {
      history.push('/auth/login/passwordless');
    }
  }, [history, campaign_slug]);

  const onBackClick = useCallback(() => {
    if (campaign_slug) {
      history.push(`/auth/signup/${campaign_slug}`);
    } else {
      history.push('/auth/login');
    }
  }, [history, campaign_slug]);

  if (isLoading) {
    return (
      <div className="d-flex flex-row justify-content-center">
        <Loading />
      </div>
    );
  }

  if (!onboardingData.language) {
    return <LanguageSelect />;
  }

  return (
    <div className="h-100 bg-auth-cyan d-flex flex-column align-items-center justify-content-center">
      <ImpactiveOrCampaignLogo campaign={campaign} />

      <div className="card fixed-auth">
        <div className="card-title bolder">{t('auth.check-your-inbox')}</div>

        <div className="text-center">{t('auth.one_time_link_sent', { email })}</div>
        <div className="text-center">{t('auth.click_link')}</div>

        <div className="text-center mb-3 mt-4">{t('auth.didnt_get_email')}</div>

        <div className="flex-row flex-column align-items-center">
          <ImpactiveButton htmlType="submit" size="large" onClick={onResendClick}>
            {t('auth.resend')}
          </ImpactiveButton>
        </div>

        <div className="d-flex flex-row align-items-center justify-content-center text-center mt-5">
          <div className="text-underline pointer" onClick={onBackClick}>
            {t('auth.back')}
          </div>
        </div>
      </div>

      {campaign && <PoweredByImpactive />}
    </div>
  );
};

PasswordlessConfirmPage.propTypes = {
  isSignup: PropTypes.bool,
};

export default PasswordlessConfirmPage;
