import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Description } from './components/TextStyles';
import Header from '@web/components/DialerActivity/components/Header';
import HelpMessage from '@web/components/DialerActivity/components/HelpMessage';
import Instructions from '@web/components/DialerActivity/components/Instructions';
import InteractiveScript from '../InCall/components/InteractiveScript';
import PageLayout from '../../components/PageLayout';
import ReadyDialog from './components/ReadyDialog';
import Tabs from '@web/components/DialerActivity/components/Tabs';

import { AuthContext } from '@web/utils/context';
import useAnswers from '../InCall/components/MainContent/useAnswers';
import useCanQueueCalls from '@web/components/DialerActivity/useCanQueueCalls';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const StyledPageLayout = styled(PageLayout)`
  min-height: 100vh;
  position: relative;
`;

function PreviewScript({ activity }) {
  const { user } = React.useContext(AuthContext);
  let { userActivityId } = useParams();
  const canQueueCalls = useCanQueueCalls(activity);
  const [simulatedAnswers, setSimulatedAnswers] = React.useState([]);
  const interactiveScriptProps = useAnswers(activity);
  const { t } = useTranslation();

  function backTo() {
    if (canQueueCalls) {
      return `/activities/${userActivityId}/phone-bank/queue-calls`;
    }
    return `/activities/${userActivityId}/`;
  }

  // MTS - We simulate the connectedContact (from Redux) here because this is
  // a preview and we do not have access to all of the values since there isn't
  // a contact until the voter is connected.
  const simulatedContact = { campaign: activity.campaign, user };

  // We simulate the answers because we don't want to modify the answers
  // in Redux.
  const simulatedProps = {
    ...interactiveScriptProps,
    answers: simulatedAnswers,
    setAnswers: setSimulatedAnswers,
  };

  const pages = [
    {
      content: (
        <>
          <HelpMessage />
          <InteractiveScript connectedContact={simulatedContact} {...simulatedProps} />
        </>
      ),
      title: t('common.script'),
    },
    {
      content: (
        <>
          <HelpMessage helpMessageKey="activity.phone_bank.instructions_help" />
          <Instructions />
        </>
      ),
      title: t('common.instructions'),
    },
  ];
  return (
    <StyledPageLayout>
      <Header activity={activity} backTo={backTo()} />
      <Description>{activity.description}</Description>
      <Tabs pages={pages} />
      <ReadyDialog sessionState={activity.session_state} />
    </StyledPageLayout>
  );
}

PreviewScript.propTypes = {
  activity: PropTypes.shape({
    campaign: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    description: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    session_state: PropTypes.string,
    title: PropTypes.string.isRequired,
  }),
};

PreviewScript.defaultProps = {};

export default PreviewScript;
