import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  DATE_FORMAT,
  DISPLAY_DATE_FORMAT,
  getLatestDate,
  iconTypes,
  showPostmarkedReceivedInfo,
  showTimeline,
  todayBeforeOrEqual,
} from './utils';
dayjs.extend(customParseFormat);

function beforePrimaryWithRegistration(votingInfo, t, state) {
  return [
    showPostmarkedReceivedInfo({
      postmarkedDate: votingInfo.mail_primary_reg_deadline_postmarked,
      postmarkedText: t('voting_information.timelines.unregistered.primaryRegistrationPostmarked'),
      receivedDate: votingInfo.mail_primary_reg_deadline_received,
      receivedText: t('voting_information.timelines.unregistered.primaryRegistrationReceived'),
    }),
    showTimeline(votingInfo.online_primary_reg_deadline) && {
      date: votingInfo.online_primary_reg_deadline,
      icon: iconTypes.dot,
      link: null,
      linkText: null,
      text: t('voting_information.timelines.unregistered.primaryOnlineRegistration'),
    },
    showTimeline(votingInfo.primary_vbm_request_deadline) && {
      date: votingInfo.primary_vbm_request_deadline,
      icon: iconTypes.dot,
      link: votingInfo.online_vbm_request_link,
      linkText: t('voting_information.timelines.shared.here'),
      text: t('voting_information.timelines.shared.primaryBallotRequestDeadline'),
    },
    showPostmarkedReceivedInfo({
      postmarkedDate: votingInfo.primary_vbm_return_deadline_postmarked,
      postmarkedText: t('voting_information.timelines.shared.primaryPostmarked'),
      receivedDate: votingInfo.primary_vbm_return_deadline_received,
      receivedText: t('voting_information.timelines.shared.primaryReceived'),
    }),
    votingInfo.automatic_vbm && {
      date: null,
      icon: iconTypes.dot,
      link: null,
      linkText: null,
      text: t('voting_information.timelines.shared.mailedBallot', {
        state: t(`states.${state}`),
      }),
    },
    showTimeline(votingInfo.primary_early_vote_start) && {
      date: votingInfo.primary_early_vote_start,
      icon: iconTypes.dot,
      link: null,
      linkText: null,
      text: t('voting_information.timelines.shared.primaryFirstDay'),
    },
    showTimeline(votingInfo.primary_early_vote_end) && {
      date: votingInfo.primary_early_vote_end,
      icon: iconTypes.dot,
      link: null,
      linkText: null,
      text: t('voting_information.timelines.shared.primaryLastDay'),
    },
    showTimeline(votingInfo.primary_election_date) && {
      date: votingInfo.primary_election_date,
      icon: iconTypes.star,
      link: votingInfo.polling_place_locator,
      linkText: t('voting_information.timelines.shared.findPolling'),
      text: t('voting_information.timelines.shared.primaryElectionDay'),
    },
  ].filter(Boolean);
}

function beforePrimaryWithoutRegistration(votingInfo, t, state, primaryDate) {
  return [
    {
      date: null,
      icon: iconTypes.dot,
      link: null,
      linkText: null,
      text: t('voting_information.timelines.unregistered.primaryRegistrationPassed', {
        date: `${dayjs(primaryDate, DATE_FORMAT).format(DISPLAY_DATE_FORMAT)}`,
      }),
    },
    showPostmarkedReceivedInfo({
      postmarkedDate: votingInfo.mail_reg_deadline_postmarked,
      postmarkedText: t('voting_information.timelines.unregistered.generalRegistrationPostmarked'),
      receivedDate: votingInfo.mail_reg_deadline_received,
      receivedText: t('voting_information.timelines.unregistered.generalRegistrationReceived'),
    }),
    showTimeline(votingInfo.online_reg_deadline) && {
      date: votingInfo.online_reg_deadline,
      icon: iconTypes.dot,
      link: null,
      linkText: null,
      text: t('voting_information.timelines.unregistered.generalOnlineRegistration'),
    },
    showTimeline(votingInfo.general_vbm_request_deadline) && {
      date: votingInfo.general_vbm_request_deadline,
      icon: iconTypes.dot,
      link: null,
      linkText: null,
      text: t('voting_information.timelines.shared.generalBallotRequestDeadline'),
    },
    showPostmarkedReceivedInfo({
      postmarkedDate: votingInfo.vbm_return_deadline_postmarked,
      postmarkedText: t('voting_information.timelines.shared.generalPostmarked'),
      receivedDate: votingInfo.vbm_return_deadline_received,
      receivedText: t('voting_information.timelines.shared.generalReceived'),
    }),
    votingInfo.automatic_vbm && {
      date: null,
      icon: iconTypes.dot,
      link: null,
      linkText: null,
      text: t('voting_information.timelines.shared.mailedBallot', {
        state: t(`states.${state}`),
      }),
    },
    showTimeline(votingInfo.early_vote_start) && {
      date: votingInfo.early_vote_start,
      icon: iconTypes.dot,
      link: null,
      linkText: null,
      text: t('voting_information.timelines.shared.generalFirstDay'),
    },
    showTimeline(votingInfo.early_vote_end) && {
      date: votingInfo.early_vote_end,
      icon: iconTypes.dot,
      link: null,
      linkText: null,
      text: t('voting_information.timelines.shared.generalLastDay'),
    },
    showTimeline(votingInfo.general_election_date) && {
      date: votingInfo.general_election_date,
      icon: iconTypes.star,
      link: votingInfo.polling_place_locator,
      linkText: t('voting_information.timelines.shared.findPolling'),
      text: t('voting_information.timelines.shared.generalElectionDay'),
    },
  ].filter(Boolean);
}

function beforeGeneralWithRegistration(votingInfo, t, state) {
  return [
    showPostmarkedReceivedInfo({
      postmarkedDate: votingInfo.mail_reg_deadline_postmarked,
      postmarkedText: t('voting_information.timelines.unregistered.generalRegistrationPostmarked'),
      receivedDate: votingInfo.mail_reg_deadline_received,
      receivedText: t('voting_information.timelines.unregistered.generalRegistrationReceived'),
    }),
    showTimeline(votingInfo.online_reg_deadline) && {
      date: votingInfo.online_reg_deadline,
      icon: iconTypes.dot,
      link: null,
      linkText: null,
      text: t('voting_information.timelines.unregistered.generalOnlineRegistration'),
    },
    showTimeline(votingInfo.general_vbm_request_deadline) && {
      date: votingInfo.general_vbm_request_deadline,
      icon: iconTypes.dot,
      link: null,
      linkText: null,
      text: t('voting_information.timelines.shared.generalBallotRequestDeadline'),
    },
    showPostmarkedReceivedInfo({
      postmarkedDate: votingInfo.vbm_return_deadline_postmarked,
      postmarkedText: t('voting_information.timelines.shared.generalPostmarked'),
      receivedDate: votingInfo.vbm_return_deadline_received,
      receivedText: t('voting_information.timelines.shared.generalReceived'),
    }),
    votingInfo.automatic_vbm && {
      date: null,
      icon: iconTypes.dot,
      link: null,
      linkText: null,
      text: t('voting_information.timelines.shared.mailedBallot', {
        state: t(`states.${state}`),
      }),
    },
    showTimeline(votingInfo.early_vote_start) && {
      date: votingInfo.early_vote_start,
      icon: iconTypes.dot,
      link: null,
      linkText: null,
      text: t('voting_information.timelines.shared.generalFirstDay'),
    },
    showTimeline(votingInfo.early_vote_end) && {
      date: votingInfo.early_vote_end,
      icon: iconTypes.dot,
      link: null,
      linkText: null,
      text: t('voting_information.timelines.shared.generalLastDay'),
    },
    showTimeline(votingInfo.general_election_date) && {
      date: votingInfo.general_election_date,
      icon: iconTypes.star,
      link: votingInfo.polling_place_locator,
      linkText: t('voting_information.timelines.shared.findPolling'),
      text: t('voting_information.timelines.shared.generalElectionDay'),
    },
  ].filter(Boolean);
}

function electionPassed(t, generalDate) {
  return [
    {
      date: null,
      icon: iconTypes.dot,
      link: null,
      linkText: null,
      text: t('voting_information.timelines.unregistered.electionPassed', {
        date: `${dayjs(generalDate, DATE_FORMAT).format(DISPLAY_DATE_FORMAT)}`,
      }),
    },
  ];
}

function getUnregisteredTimelines(votingInfo, t, state) {
  const today = dayjs();

  const primaryDate = votingInfo.primary_election_date;
  const generalDate = votingInfo.general_election_date;

  const showPrimary = todayBeforeOrEqual(primaryDate);

  const showGeneral =
    today.isAfter(dayjs(primaryDate, DATE_FORMAT)) && todayBeforeOrEqual(generalDate);

  if (showPrimary) {
    const primaryRegistrationAvailable = getLatestDate([
      votingInfo.mail_primary_reg_deadline_postmarked,
      votingInfo.mail_primary_reg_deadline_received,
      votingInfo.online_primary_reg_deadline,
    ]);

    if (primaryRegistrationAvailable) {
      return beforePrimaryWithRegistration(votingInfo, t, state);
    }

    return beforePrimaryWithoutRegistration(votingInfo, t, state, primaryDate);
  }

  if (showGeneral) {
    const generalRegistrationAvailable = getLatestDate([
      votingInfo.mail_reg_deadline_postmarked,
      votingInfo.mail_reg_deadline_received,
      votingInfo.online_reg_deadline,
    ]);

    if (generalRegistrationAvailable) {
      return beforeGeneralWithRegistration(votingInfo, t, state);
    }
  }

  return electionPassed(t, generalDate);
}

export default getUnregisteredTimelines;
