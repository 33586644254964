import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { Cookies } from 'react-cookie';

import Status from '@web/constants/contactRegistrationStatusTypes';

const initialState = {
  contactFormResult: {},
  formValues: null,
  friendLookUpResult: {},
  hasStateId: null,
  isCheckedVoterRegistration: false,
  isRegistrationRecheck: false,
  selectedVoter: {},
};

const getDateValue = date => {
  if (date) {
    return dayjs(date).format('MM/DD/YYYY');
  }
  return '';
};

const voterRegistration = createSlice({
  initialState,
  name: 'voterRegistration',
  reducers: {
    init: () => initialState,
    setContactFormResult: (state, action) => {
      const activity = action.payload.activity;
      const contact = action.payload.contact;

      const cookies = new Cookies(null, { path: '/' });
      const fanOutCookies = cookies.get('fan_out_auth');

      // fan out pseudo auth flow
      if (activity?.campaign?.fan_out_registration_enabled) {
        const prevContact = JSON.parse(localStorage.getItem('fan_out_contact'));
        const activities = JSON.parse(localStorage.getItem('fan_out_contact_activities') || '[]');

        // find current activity in cookies
        const restoredActivity = fanOutCookies?.[0]?.activities?.find(
          a =>
            // convert activity_id and campaign_id to number for correct comparison
            activity.id === Number(a.activity_id) && activity.campaign.id === Number(a.campaign_id),
        );

        // remove data if prev phone number exist and changed
        const newContact = !!prevContact?.phone && prevContact?.phone !== contact?.phone;

        const contactToStore = fanOutCookies
          ? // always use fan out cookies if they exist
            {
              ...contact,
              referral_link: restoredActivity?.referral_link || contact.referral_link || null,
              registration_status: restoredActivity?.registration_status || Status.UNCONFIRMED,
            }
          : newContact
          ? // use current contact if it's a new one
            contact
          : // use prev contact if it's the same contact and just update the referral link in another activity
          contact?.registration_status === Status.REFERRAL_LINK
          ? prevContact
            ? { ...prevContact, referral_link: contact?.referral_link }
            : contact
          : contact;

        if (newContact) {
          localStorage.setItem('fan_out_contact_activities', JSON.stringify([]));
        } else {
          const registrationStatus =
            contact?.registration_status === Status.REGISTERED ||
            contact?.registration_status === Status.UNREGISTERED
              ? contact?.registration_status
              : undefined;

          let newActivities;

          // add new activity
          if (
            !activities.some(a => activity.id === a.id && activity.campaign.id === a.campaign.id)
          ) {
            newActivities = [
              ...activities,
              {
                ...activity,
                referral_link: contact?.referral_link,
                registration_status: registrationStatus,
              },
            ];
            // update existing one
          } else {
            newActivities = activities.map(a => {
              if (activity.id === a.id && activity.campaign.id === a.campaign.id) {
                return {
                  ...a,
                  referral_link: contact?.referral_link,
                  registration_status: registrationStatus,
                };
              }
              return a;
            });
          }
          localStorage.setItem('fan_out_contact_activities', JSON.stringify(newActivities));
        }

        localStorage.setItem('fan_out_contact', JSON.stringify(contactToStore));

        return { ...state, contactFormResult: contactToStore };
      }
      // save contact to local storage for common VR flow & headcount
      else {
        // save contact to local storage
        if (contact && Object.keys(contact).length !== 0) {
          // save data that needs for forms
          const vrContact = {
            address: contact.address || '',
            city: contact.city || '',
            county_name: contact.county_name,
            date_of_birth: getDateValue(contact.date_of_birth) || null,
            email: contact.email || '',
            first_name: contact.first_name || '',
            last_name: contact.last_name || '',
            phone: contact.phone || '',
            state_abbrev: contact.state_abbrev || '',
            stateId: contact.stateId || null,
            unit_number: contact.unit_number || '',
            zip_code: contact.zip_code || '',
          };
          localStorage.setItem('vr_and_form_contact', JSON.stringify(vrContact));
        }
      }

      return { ...state, contactFormResult: action.payload.contact };
    },

    // Set form values to use them to prefill contact form when user click "Search again"
    // Note: we can't use contactFormResult because it returns different data then user filled in form
    setFormValues: (state, action) => {
      const contact = action.payload;
      return {
        ...state,
        formValues: {
          address: contact.address || '',
          address2: contact.address2 || '',
          city: contact.city || '',
          county_name: contact.countyName,
          date_of_birth: getDateValue(contact.date_of_birth) || null,
          email: contact.email || '',
          first_name: contact.firstName || '',
          last_name: contact.lastName || '',
          phone: contact.phone || '',
          state_abbrev: contact.stateAbbrev || '',
          stateId: contact.stateId || null,
          unit_number: contact.unitNumber || '',
          zip_code: contact.zipCode || '',
        },
      };
    },

    setFriendLookUpResult: (state, action) => ({ ...state, friendLookUpResult: action.payload }),

    setHasStateId: (state, action) => ({ ...state, hasStateId: action.payload }),

    setIsCheckedVoterRegistration: (state, action) => ({
      ...state,
      isCheckedVoterRegistration: action.payload,
    }),

    setIsRegistrationRecheck: (state, action) => ({
      ...state,
      isRegistrationRecheck: action.payload,
    }),

    setSelectedVoter: (state, action) => ({ ...state, selectedVoter: action.payload }),

    setStateIdWithAddressForm: (state, action) => ({
      ...state,
      contactFormResult: action.payload.contact,
      hasStateId: action.payload.hasStateId,
      selectedVoter: action.payload.voter,
    }),
  },
});

export const {
  init,
  setContactFormResult,
  setFormValues,
  setFriendLookUpResult,
  setIsCheckedVoterRegistration,
  setIsRegistrationRecheck,
  setHasStateId,
  setSelectedVoter,
  setStateIdWithAddressForm,
} = voterRegistration.actions;

export default voterRegistration.reducer;
