import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import MessageThreadPreviewContent from './MessageThreadPreview';

import { AuthContext, StateContext } from 'web/utils/context';
import ImpactiveTypes from '@web/../types';
import { getOtherUser, getFullName, getRelationshipLabel } from '../utils';

const MessageThreadPreview = props => {
  const {
    messageThread,
    messageThread: {
      id: messageThreadId,
      contact,
      kind,
      unread: initialUnread,
      users,
      messages_count: totalMessageThreadMessagesCount,
      is_staff,
      is_outvote,
      is_report_filled: isReportFilled,
    },
    setMessageThreadParams,
    activeMessageThreadId,
  } = props;
  const { user } = useContext(AuthContext);
  const { decreaseUnreadMessagesCount } = useContext(StateContext);
  const [unread, setUnread] = useState(initialUnread);

  const { t } = useTranslation();

  useEffect(() => {
    if (activeMessageThreadId !== messageThreadId) {
      setUnread(initialUnread);
    } else {
      setUnread(false);
    }
  }, [initialUnread, activeMessageThreadId, messageThreadId]);

  const not_me = contact ? contact : getOtherUser(user && user.id, users);
  if (!not_me) return null;

  const firstName = not_me.first_name;
  const lastName = not_me.last_name;
  const name = getFullName(firstName, lastName);

  const constructThreadLink = () => {
    setMessageThreadParams(messageThreadId);
    setUnread(false);

    // When the message thread was unread we want to update (decrease) unread messages count
    if (unread) {
      decreaseUnreadMessagesCount();
    }
  };

  const countOfMessages = totalMessageThreadMessagesCount
    ? `(${totalMessageThreadMessagesCount})`
    : null;

  const relationship = getRelationshipLabel(kind, is_staff, is_outvote, user, t);

  return (
    <MessageThreadPreviewContent
      constructThreadLink={constructThreadLink}
      countOfMessages={countOfMessages}
      messageThread={messageThread}
      name={name}
      unread={unread}
      relationship={relationship}
      isReportFilled={isReportFilled}
      isActive={activeMessageThreadId === messageThreadId}
    />
  );
};

MessageThreadPreview.propTypes = {
  activeMessageThreadId: PropTypes.number,
  messageThread: ImpactiveTypes.messageThread,
  setMessageThreadParams: PropTypes.func.isRequired,
};

export default MessageThreadPreview;
