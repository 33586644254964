import { List } from 'antd';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';
import { usePaginatedApi, toObject } from '../../../hooks/usePaginatedApi';
import { campaigns, user } from '../../../services/api';
import { media } from '../../../styles/theme';
import ActivityPreview from '../../Activity/ActivityPreview';
import ActivityPreviewModal from '../../Activity/ActivityPreviewModal';
import ActivityFilters from '../../common/ActivityFilters';
import Loading from '../../common/Loading';
import Title from '../../common/Title';
import { AuthContext } from '@web/utils/context';

const ActivityListContainer = styled.div`
  margin-top: 2em;
`;

const StyledList = styled(List)`
  .ant-list-items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-right: -12px;
  }
`;

const ActivityItem = styled(List.Item)`
  flex: 0 100%;
  &&& {
    border-bottom: 0;
    padding: 12px 12px 0px 0;
  }

  // Show 2 items per row on large screens
  ${media.lg} {
    flex: 0 50%;
  }
`;

const ActivityList = ({ campaign, campaignJoined, joinCampaign, initialActivityId }) => {
  const { user: userData } = useContext(AuthContext);
  const { t } = useTranslation();
  const [filters, setFilters] = useState({
    activity_type: undefined,
    cause: undefined,
    zip: undefined,
  });

  // Depending on whether or not campaign is joined we use different endpoint with different parameters
  const apiEndpoint = campaignJoined ? user.getUserActivities : campaigns.getCampaignActivities;
  const resourceId = campaignJoined ? null : campaign.id;
  const params = campaignJoined ? { campaign_id: campaign.id, ...filters } : filters;

  const {
    hasMore,
    initialLoading,
    items,
    loading,
    loadMore: loadMoreActivities,
    resetPagination,
  } = toObject(usePaginatedApi(apiEndpoint, resourceId, params));

  const [selectedAction, setSelectedAction] = useState();

  // MTS - This is temporary code to make sure we don't render campaign activities in "draft" state.
  // It is going to be fixed on the backend at some point.
  const filteredItems = useMemo(
    () =>
      items
        .filter(item => !(item?.activity?.aasm_state && item?.activity?.aasm_state === 'draft'))
        // If a specific language is selected for an activity, we need to check if it matches the language selected by the user
        .filter(item => !item.activity?.locale || item.activity?.locale === userData?.locale),
    [items, userData?.locale],
  );

  const onlyActionItems = useMemo(
    () =>
      filteredItems.filter(
        item =>
          ![
            'UserBroadcastActivity',
            'IdVotersActivity',
            'AssignedCanvassingActivity',
            'RegistrationActivity',
          ].includes(item?.activity?.type),
      ),
    [filteredItems],
  );

  const [scrollRef, setScrollRef] = useState(null);

  useEffect(() => {
    resetPagination({ refetch: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignJoined, campaign]);

  // Since we don't have a separate activity page, we need to open a activity modal on the campaign page from which this activity is
  useEffect(() => {
    if (!initialActivityId || !items) return;
    setSelectedAction(items.find(action => action.id === Number(initialActivityId)));
  }, [initialActivityId, items]);

  const shouldRenderInfiniteScroll = loading || (filteredItems && filteredItems.length > 0);
  const showNoResultsMessage = !shouldRenderInfiniteScroll && !initialLoading;

  const handleClick = item => {
    setSelectedAction({
      activity: item,
      completed: false,
      onActivityActionButtonPress: joinCampaign,
    });
  };

  // item will be either userActivity or activity - depending on whether campaign is joined or not
  const renderItem = item => {
    const { _type: activityType } = item;

    if (activityType === 'user_activities') {
      // When the campaign is joined we're dealing with user activities
      const { activity, completed } = item;
      return (
        <ActivityItem onClick={() => setSelectedAction(item)}>
          <ActivityPreview activity={activity} completed={completed} userActivity={item} />
        </ActivityItem>
      );
    } else {
      // Otherwise we're dealing with campaign activities, and render it accordingly
      return (
        <ActivityItem onClick={() => handleClick(item)}>
          <ActivityPreview
            activity={item}
            completed={false}
            onActivityActionButtonPress={joinCampaign}
          />
        </ActivityItem>
      );
    }
  };

  const onFilterChangeHandler = useCallback(
    newFilters => {
      if (!isEqual(filters, newFilters)) {
        setFilters(newFilters);
        resetPagination({ refetch: true });
      }
    },
    [filters, resetPagination],
  );

  return (
    <>
      <Title>{t('campaign.actions')}</Title>
      <ActivityFilters initialValues={filters} onChange={onFilterChangeHandler} />
      <ActivityListContainer ref={setScrollRef}>
        {shouldRenderInfiniteScroll && (
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            threshold={0}
            loadMore={loadMoreActivities}
            hasMore={!loading && hasMore}
            useWindow
            getScrollParent={() => scrollRef}
          >
            <StyledList dataSource={onlyActionItems} renderItem={renderItem}>
              {(loading || !onlyActionItems) && <Loading centered />}
            </StyledList>
          </InfiniteScroll>
        )}
        {showNoResultsMessage && t('campaign.no_actions_found')}
      </ActivityListContainer>

      <ActivityPreviewModal
        visible={!!selectedAction}
        onCancel={() => setSelectedAction(null)}
        activity={selectedAction?.activity}
        completed={selectedAction?.completed}
        userActivity={selectedAction?.onActivityActionButtonPress ? null : selectedAction}
        onActivityActionButtonPress={selectedAction?.onActivityActionButtonPress}
      />
    </>
  );
};

ActivityList.propTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  campaignJoined: PropTypes.bool.isRequired,
  initialActivityId: PropTypes.string,
  joinCampaign: PropTypes.func.isRequired,
};

ActivityList.defaultProps = {
  initialActivityId: null,
};

export default ActivityList;
