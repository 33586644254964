import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';

const SectionTitle = styled.div`
  font-family: ${({ theme, regular }) => (regular ? theme.fonts.regular : theme.fonts.semibold)};
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
`;

const Customization = styled.div`
  width: 100%;
`;

const TextFieldContainer = styled.div``;

const SelectionFieldRadio = styled.div``;

const RadioStyled = styled(Radio)`
  &&& {
    margin: 10px 5px 10px 0;
    font-family: ${({ theme, regular }) => (regular ? theme.fonts.regular : theme.fonts.semibold)};
    color: ${({ theme }) => theme.colors.black};
    font-size: 1rem;
  }
`;

const StyledRadioGroup = styled(Radio.Group)`
  &&& {
    display: flex;
    flex-direction: column;
  }
`;

const CustomFieldsList = ({ customizations, setUserReportCustomizations }) => {
  const onCustomFieldTextUpdate = useCallback(
    (selectedSlug, e) => {
      const updatedFields = customizations.map(field => {
        if (field.slug !== selectedSlug) {
          return field;
        }
        return {
          ...field,
          // When the report is empty, the field's default value is null,
          // so if the user has filled in something and then cleared the field,
          // we'll need the same value as the initial to disable the button
          value: e.target.value !== '' ? e.target.value : null,
        };
      });
      setUserReportCustomizations(updatedFields);
    },
    [customizations, setUserReportCustomizations],
  );

  const onCustomFieldSelectUpdate = useCallback(
    (selectedSlug, val) => {
      const updatedFields = customizations.map(field => {
        if (field.slug !== selectedSlug) {
          return field;
        }
        return {
          ...field,
          value: val,
        };
      });
      setUserReportCustomizations(updatedFields);
    },
    [customizations, setUserReportCustomizations],
  );

  const handleRadioClick = useCallback(
    (field, value) => {
      onCustomFieldSelectUpdate(field.slug, field.value === value ? null : value);
    },
    [onCustomFieldSelectUpdate],
  );

  return customizations.map(field => {
    if (
      (field.type === 'SelectionField' && field.available_values.length > 0) ||
      field.type === 'TextField'
    ) {
      return (
        <Customization className="py-3" key={field.slug}>
          {field.type === 'TextField' && (
            <TextFieldContainer>
              <SectionTitle className="pb-2">{field.prompt || field.name}</SectionTitle>

              <TextArea
                name={field.slug}
                value={field.value}
                autoSize={{ minRows: 4 }}
                onChange={e => onCustomFieldTextUpdate(field.slug, e)}
              />
            </TextFieldContainer>
          )}

          {field.type === 'SelectionField' && (
            <SelectionFieldRadio>
              <SectionTitle className="pb-2">{field.prompt || field.name}</SectionTitle>
              <StyledRadioGroup value={field.value}>
                {field.available_values.map((val, fieldIndex) => (
                  <RadioStyled
                    key={fieldIndex}
                    onClick={() => handleRadioClick(field, val)}
                    regular={field.value !== val}
                    value={val}
                  >
                    {val}
                  </RadioStyled>
                ))}
              </StyledRadioGroup>
            </SelectionFieldRadio>
          )}
        </Customization>
      );
    }
    return null;
  });
};

CustomFieldsList.propTypes = {
  customizations: PropTypes.array.isRequired,
  setUserReportCustomizations: PropTypes.func.isRequired,
};

export default CustomFieldsList;
