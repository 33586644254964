import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import i18n from '@web/components/i18n';
import { AuthContext } from '@web/utils/context';
import PhoneNumberInput from '@web/components/common/shared/PhoneNumberInput';
import FormInput from '@web/components/common/shared/FormInput';
import ImpactiveButton from '../../ImpactiveButton';

const { t: translate } = i18n;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 14px;
`;

const validationSchema = yup.object().shape({
  first_name: yup.string().nullable().required(translate('auth.complete_auth.errors.first_name')),
  last_name: yup.string().nullable().required(translate('auth.complete_auth.errors.last_name')),
  phone: yup
    .string()
    .nullable()
    .matches(/^(\1)?[0-9]{11}$/, translate('auth.complete_auth.errors.invalid_phone'))
    .required(translate('auth.complete_auth.errors.phone')),
  zip: yup
    .string()
    .nullable()
    .matches(/^\d{5}$/, translate('auth.complete_auth.errors.invalid_zip'))
    .required(translate('auth.complete_auth.errors.zip')),
});

const CompleteAuthForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  const initialValues = useMemo(
    () => ({
      first_name: user?.first_name,
      last_name: user?.last_name,
      phone: user?.phone,
      zip: user?.supplied_zip_code,
    }),
    [user],
  );

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      onSubmit(values, setSubmitting);
    },
    [onSubmit],
  );

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}
      render={({
        values,
        isSubmitting,
        handleChange,
        submitForm,
        setFieldValue,
        errors,
        touched,
      }) => {
        return (
          <StyledForm>
            <FormInput
              name="first_name"
              label={t('auth.complete_auth.placeholders.first_name')}
              placeholder={t('auth.complete_auth.placeholders.first_name')}
              withoutLabel
              value={values.first_name}
              onChange={handleChange}
            />
            <FormInput
              name="last_name"
              label={t('auth.complete_auth.placeholders.last_name')}
              placeholder={t('auth.complete_auth.placeholders.last_name')}
              withoutLabel
              value={values.last_name}
              onChange={handleChange}
            />
            <PhoneNumberInput
              name="phone"
              label={t('auth.complete_auth.placeholders.phone')}
              placeholder={t('auth.complete_auth.placeholders.phone')}
              isRequired
              withoutLabel
              // remove country code from phone number at the very beginning
              country={''}
              value={values.phone}
              onChange={e => setFieldValue('phone', e)}
            />
            <FormInput
              name="zip"
              formik={{ errors, touched, values }}
              label={t('auth.complete_auth.placeholders.zip')}
              placeholder={t('auth.complete_auth.placeholders.zip')}
              withoutLabel
              value={values.zip}
              onChange={handleChange}
              maxLength={5}
            />
            <div className="d-flex flex-row align-items-center justify-content-center mt-5 text-center">
              <ImpactiveButton size="large" htmlType="submit" disabled={isSubmitting}>
                {t('common.submit')}
              </ImpactiveButton>
            </div>
          </StyledForm>
        );
      }}
    />
  );
};

CompleteAuthForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default CompleteAuthForm;
