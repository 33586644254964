import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import styled from 'styled-components';
import TerminateModal from './TerminateModal';
import usePatchThrough from '@web/components/DialerActivity/usePatchThrough';
import ImpactiveButton from '@web/components/ImpactiveButton';

const StyledPatchThroughInfo = styled.div`
  background-color: #eee;
  border-radius: 7px;
  padding: 25px 40px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
  white-space: pre;
  font-size: 26px;
  margin-bottom: 32px;
  text-align: center;
  ${({ theme }) => theme.fonts.regular};
`;

const TerminateWarningText = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
  ${({ theme }) => theme.fonts.regular};
`;

const TerminateButton = styled(ImpactiveButton)`
  width: 100%;
  height: 45px;
  text-transform: none;
  font-family: ${({ theme }) => theme.fonts.boldItalic};
  font-size: 16px;
  height: 44px;
  letter-spacing: 1.4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PatchThroughInfo = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { connectedContact: contact } = useSelector(state => state.dialer);
  const { handleTerminate } = usePatchThrough();

  const name = useMemo(() => `${contact?.first_name || ''} ${contact?.last_name || ''}`, [contact]);

  const onModalOpen = useCallback(() => setIsModalOpen(true), []);
  const onTerminate = useCallback(() => {
    setIsModalOpen(false);
    handleTerminate();
  }, [handleTerminate]);

  return (
    <StyledPatchThroughInfo>
      <Title>{t('activity.phone_bank.patch_through.description', { name })}</Title>
      <TerminateButton onClick={onModalOpen}>
        {t('activity.phone_bank.patch_through.terminate_the_call')}
      </TerminateButton>
      <TerminateWarningText>
        {t('activity.phone_bank.patch_through.terminate_warning', { name })}
      </TerminateWarningText>
      <TerminateModal
        isModalOpen={isModalOpen}
        modalHandler={setIsModalOpen}
        name={name}
        onTerminate={onTerminate}
      />
    </StyledPatchThroughInfo>
  );
};

PatchThroughInfo.propTypes = {};

export default PatchThroughInfo;
