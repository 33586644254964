import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import dialerMachine, { checkTransition } from './dialerMachine';

const initialState = {
  activityId: null,
  callData: null,
  connectedContact: null,
  currentAnswers: [],
  currentState: dialerMachine.initial,
  disabledPatchThrough: [],
  loading: false,
  reportData: null,
  sessionStartTime: null,
  totalCalls: 0,
  volunteerPhone: '',
  voter_call_id: null,
  waitTime: 0,
};

const dialer = createSlice({
  initialState,
  name: 'dialer',
  reducers: {
    callComplete: (state, action) => {
      return {
        ...state,
        connectedContact: null,
        currentAnswers: [],
        disabledPatchThrough: [],
        totalCalls: state.totalCalls + 1,
        voter_call_id: null,
      };
    },

    init: () => initialState,

    machineTransition: (state, action) => {
      const machineState = dialerMachine.transition(state.currentState, { type: null });
      const nextState = dialerMachine.transition(state.currentState, { type: action.payload });
      checkTransition(state, action, machineState);
      return {
        ...state,
        currentState: nextState?.value,
      };
    },

    reportUpdated: (state, action) => ({
      ...state,
      reportData: action.payload,
    }),

    setActivityId: (state, action) => ({ ...state, activityId: action.payload }),

    setCallData: (state, action) => ({ ...state, callData: action.payload }),

    setCurrentAnswers: (state, action) => ({ ...state, currentAnswers: action.payload }),

    setDialerLoading: (state, action) => ({ ...state, loading: action.payload }),

    setDisabledPatchThrough: (state, action) => ({
      ...state,
      disabledPatchThrough: action.payload,
    }),

    setVolunteerPhone: (state, action) => ({ ...state, volunteerPhone: action.payload }),

    setWaitTime: (state, action) => ({ ...state, waitTime: action.payload }),

    volunteerConnected: state => ({
      ...state,
      sessionStartTime: state.sessionStartTime ? state.sessionStartTime : dayjs(),
    }),

    volunteerReconnected: state => {
      return {
        ...state,
        connectedContact: null,
        currentAnswers: [],
        voter_call_id: null,
      };
    },

    voterJoined: (state, action) => ({
      ...state,
      connectedContact: action.payload,
      voter_call_id: action.payload?.voter_voice_call_id,
    }),
  },
});

export const {
  init,
  callComplete,
  machineTransition,
  setActivityId,
  setDialerLoading,
  setDisabledPatchThrough,
  setCallData,
  setCurrentAnswers,
  setCurrentState,
  setSurveyComplete,
  setVolunteerConnected,
  setVolunteerPhone,
  setWaitTime,
  volunteerConnected,
  volunteerReconnected,
  voterJoined,
  reportUpdated,
} = dialer.actions;

export default dialer.reducer;
