import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { track } from '../../services/analytics';
import { users } from '../../services/api';
import { AuthContext } from '../../utils/context';
import Loading from '../common/Loading';
import { setAppLanguage } from '../i18n';
import ImpactiveOrCampaignLogo from './Components/ImpactiveOrCampaignLogo';
import PoweredByImpactive from './Components/PoweredByImpactive';
import ImpactiveButton from '../ImpactiveButton';

const LanguageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  text-align: center;
`;

const LanguageSelect = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { campaign_slug } = useParams();

  useDocumentTitle(t('auth.language.choose_language'));
  const { onboardingData, setOnboardingData, campaign, isLoading, setCampaignSlug, user, setUser } =
    useContext(AuthContext);

  useEffect(() => {
    // Track the page view
    track('VIEW_LANGUAGE_SELECT_SCREEN');
    setCampaignSlug(campaign_slug);
  }, [campaign_slug, setCampaignSlug]);

  useEffect(() => {
    // wait for all the data to be loaded
    if (isLoading) {
      return;
    }

    // set onboarding language if user has locale
    if (!onboardingData.language && user?.locale) {
      setAppLanguage(user?.locale);
      setOnboardingData({ ...onboardingData, language: user?.locale });
    }
  }, [isLoading, user, onboardingData, setOnboardingData]);

  const setLanguage = useCallback(
    language => {
      setAppLanguage(language);
      setOnboardingData({ ...onboardingData, language });
      if (user) {
        users.updateMe({ locale: language }).then(({ data }) => {
          setUser(data.data);
        });
      }

      if (history.location.pathname.includes('/auth/language')) {
        if (campaign_slug) {
          history.push(`/auth/signup/${campaign_slug}`);
        } else {
          history.push(`/auth/login`);
        }
      }
    },
    [campaign_slug, history, onboardingData, setOnboardingData, setUser, user],
  );

  if (isLoading) {
    return (
      <div className="d-flex flex-row justify-content-center">
        <Loading />
      </div>
    );
  }

  return (
    <div
      className={`h-100 bg-auth-cyan d-flex flex-column align-items-center justify-content-center`}
    >
      <ImpactiveOrCampaignLogo campaign={campaign} />

      <div className="card fixed-auth">
        <div className={`card-title bolder ${campaign ? '' : 'mb-4'}`}>
          {t('auth.language.choose_language')}
        </div>
        <LanguageContainer>
          <ImpactiveButton size="full-width" onClick={() => setLanguage('en')}>
            {t('auth.language.english')}
          </ImpactiveButton>
          <ImpactiveButton size="full-width" onClick={() => setLanguage('es')}>
            {t('auth.language.spanish')}
          </ImpactiveButton>
        </LanguageContainer>
      </div>

      {campaign && <PoweredByImpactive />}
    </div>
  );
};

LanguageSelect.propTypes = {
  isSignup: PropTypes.bool,
};

export default LanguageSelect;
