import { message } from 'antd';

import i18n from '@web/components/i18n';

export const teamJoinCodeError = error => {
  const errorMessage =
    error?.response?.status === 403
      ? i18n.t('auth.join.blocked_user')
      : error?.response?.data?.message || error?.message || i18n.t('auth.join.error');

  message.error(errorMessage);
};
