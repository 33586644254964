import { Button, message } from 'antd';
import { bool, func, string } from 'prop-types';
import React from 'react';
import GoogleLogin from 'react-google-login';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { authErrorMessage as googleAuthErrorMessage } from 'web/services/google/auth';

import googleIconImage from '../../../../../assets/images/web/google-icon.png';
import ImpactiveButton from '../../ImpactiveButton';

const GmailButon = styled(Button)`
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
`;

const Google = () => (
  <img
    src={googleIconImage}
    width={25}
    height={25}
    alt="google-icon"
    style={{ marginRight: '8px' }}
  />
);

// TODO: move to AuthHelpers
const AuthMenu = ({
  onPasswordlessLogin,
  onGoogleLogin,
  onEmailLogIn,
  passwordlessButtonText,
  includePasswordOption,
  includeOrBreak,
  passwordlessExplainText,
  hidePasswordLessText,
}) => {
  const { t } = useTranslation();

  // const { t } = useTranslation();
  const handleGoogleSuccess = response => {
    const { googleId, profileObj, tokenId } = response;
    const { email } = profileObj;
    onGoogleLogin(email, tokenId, googleId);
  };

  const handleGoogleFailure = response => {
    const { error } = response;
    const errorMessage = googleAuthErrorMessage(error);

    if (errorMessage) {
      message.error(errorMessage);
    }
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <ImpactiveButton size="large" onClick={onPasswordlessLogin}>
        {passwordlessButtonText}
      </ImpactiveButton>

      <div className={`${hidePasswordLessText ? 'p-2' : 'p-3'}`}>
        {!hidePasswordLessText && (
          <div className="text-center">
            {passwordlessExplainText || t('auth.auth_modal.one_time_link')}
          </div>
        )}
        {!!includePasswordOption && (
          <div className="d-flex flex-row align-items-center justify-content-center">
            <div className="mr-1">{t('common.or')}</div>
            <div onClick={onEmailLogIn} className="text-underline pointer">
              {t('auth.auth_modal.with_email')}
            </div>
          </div>
        )}
      </div>

      {includeOrBreak && (
        <div className="flex-row align-items-center w-100">
          <div className="line-break flex1"></div>
          <div className="text-muted mx-1">{t('common.or')}</div>
          <div className="line-break flex1"></div>
        </div>
      )}

      <GoogleLogin
        clientId={process.env.GOOGLE_CLIENT_ID}
        theme="dark"
        onSuccess={handleGoogleSuccess}
        onFailure={handleGoogleFailure}
        cookiePolicy="single_host_origin"
        render={renderProps => (
          <GmailButon type="default" onClick={renderProps.onClick} disabled={renderProps.disabled}>
            {Google()} {t('auth.auth_modal.continue_with_google')}
          </GmailButon>
        )}
      />
    </div>
  );
};

AuthMenu.propTypes = {
  hidePasswordLessText: bool,
  includeOrBreak: bool,
  includePasswordOption: bool,
  onEmailLogIn: func,
  onGoogleLogin: func,
  onPasswordlessLogin: func,
  passwordlessButtonText: string,
  passwordlessExplainText: string,
};

AuthMenu.defaultProps = {
  hidePasswordLessText: false,
  includeOrBreak: true,
  includePasswordOption: true,
  passwordlessButtonText: 'Sign in with Email',
  passwordlessExplainText: null, //t('auth.auth_modal.one_time_link')
};

export default AuthMenu;
