import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Body, Title } from '@web/components/DialerActivity/components/TextStyles';
import BackButton from '../../components/BackButton';
import NextButton from '../../components/NextButton';
import Page, { CopyWrapper, PageCopy } from '../../components/Page';
import PhoneIcon from '@web/components/DialerActivity/icons/Phone';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from '@admin/utils/formatPhoneNumber';
import Button from '@web/components/DialerActivity/components/Button';
import DotsTyping from '@web/components/DotsTypingAnimation';

const StyledButton = styled(Button)`
  width: 227px;
  margin-top: 8px;
  &&& {
    color: ${({ theme }) => theme.colors.blue};
  }
`;

function Dialing({ onBack, onNext, onDoNotReceiveCall }) {
  const { t } = useTranslation();

  const dialerState = useSelector(state => state.dialer);
  const { callData, volunteerPhone } = dialerState;

  const handleCallDoNotReceived = onDoNotReceiveCall ?? onBack;

  return (
    <Page>
      <PhoneIcon size={118} />
      <PageCopy>
        <CopyWrapper>
          <Title>
            {t('activity.phone_bank.were_calling')} {formatPhoneNumber(volunteerPhone)}
            <DotsTyping />
          </Title>
          <Body
            style={{ marginBottom: 20 }}
            dangerouslySetInnerHTML={{
              __html: t('activity.phone_bank.you_will_receive_a_call', {
                phone: formatPhoneNumber(callData?.campaign_number),
              }),
            }}
          />
        </CopyWrapper>
      </PageCopy>
      <NextButton onClick={onNext}>{t('activity.phone_bank.call_answered')}</NextButton>
      <StyledButton variant="outline" onClick={handleCallDoNotReceived}>
        {t('activity.phone_bank.call_do_not_received')}
      </StyledButton>
      <BackButton onClick={onBack}>{t('common.cancel')}</BackButton>
    </Page>
  );
}

Dialing.propTypes = {
  dialerState: PropTypes.shape({
    callData: PropTypes.shape({
      campaign_number: PropTypes.string,
    }),
    phoneNumber: PropTypes.string.isRequired,
  }),
  onBack: PropTypes.func.isRequired,
  onDoNotReceiveCall: PropTypes.func,
  onNext: PropTypes.func.isRequired,
};

export default Dialing;
