import axios from 'axios';
import routes from './routes';

const { activities: route, general_activities } = routes;

const activityRoutes = {
  getActivityGoals: async ({ campaignSlug, activitySlug }) => {
    const prefix = route.replace(':campaign_id', campaignSlug);
    return axios.get(`${prefix}/${activitySlug}/goals`);
  },

  getP2PUsersLeaderboard: async ({ campaignSlug, activitySlug, page = 1, perPage = 10 }) =>
    axios.get(
      `/api/v2/campaigns/${campaignSlug}/activities/${activitySlug}/p2p_texting_leaderboard`,
      { params: { page, per_page: perPage } },
    ),

  getPublicActivity: async ({ campaignSlug, activityId }) => {
    const prefix = route.replace(':campaign_id', campaignSlug);
    return axios.get(`${prefix}/${activityId}/show`);
  },

  getUploadFileUrl: async (contentType, objectName) => {
    return axios.get(`/api/v2/client/activities/upload_file.json`, {
      params: { contentType, objectName, uploadType: 'file' },
    });
  },

  getVoterRegistrationContactLeaderboard: async ({
    campaignSlug,
    activitySlug,
    page = 1,
    perPage = 10,
  }) =>
    axios.get(
      `/api/v2/campaigns/${campaignSlug}/activities/${activitySlug}/contact_referrals_leaderboard`,
      { params: { page, per_page: perPage } },
    ),

  getVoterRegistrationTeamLeaderboard: async ({ campaignSlug, activitySlug, page, perPage }) =>
    axios.get(
      `/api/v2/campaigns/${campaignSlug}/activities/${activitySlug}/team_referrals_leaderboard`,
      { params: { page, per_page: perPage } },
    ),

  submitSurveyActivityForm: async ({
    formSlug,
    campaignSlug,
    referralHash,
    surveyFormData,
    questionAnswers,
    autoOpenLink,
  }) =>
    axios.post(`${routes.campaigns}/${campaignSlug}/surveys/${formSlug}/submit`, {
      auto_open_link: autoOpenLink,
      question_answers: questionAnswers,
      referral_hash: referralHash,
      survey_activity_form_submission: {
        ...surveyFormData,
      },
    }),

  trackExternalLinkClick: async ({ campaignId, activitySlug, activityId, contactId, url }) =>
    axios.post(`${general_activities}/${activityId}/track_external_link_click`, {
      campaign_id: campaignId,
      contact_id: contactId,
      id: activitySlug,
      url,
    }),
};

export default activityRoutes;
