import { iconTypes } from './utils';

const noOnyearDataYet = (t, state) => ({
  date: null,
  icon: iconTypes.dot,
  link: null,
  linkText: null,
  text: t('voting_information.timelines.shared.no-onyear-data-yet', {
    state: t(`states.${state}`),
  }),
});

const hasOneyearData = (t, state) => ({
  date: null,
  icon: iconTypes.dot,
  link: null,
  linkText: null,
  text: t('voting_information.timelines.shared.has-onyear-data', {
    state: t(`states.${state}`),
  }),
});

export { noOnyearDataYet, hasOneyearData };
