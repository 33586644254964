import React from 'react';
import styled from 'styled-components';

import { Spin } from 'antd';
import TopSection from './components/TopSection';
import LeaderboardTable from './components/LeaderboardTable';

import useLeaderboard from './useLeaderboard';

const Leaderboard = styled.div`
  padding: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Center = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PeerToPeerPublicLeaderboard = () => {
  const leaderboardControls = useLeaderboard();

  if (leaderboardControls.loading) {
    return (
      <Center>
        <Spin />
      </Center>
    );
  }

  return (
    <Leaderboard>
      <TopSection controls={leaderboardControls} />
      <LeaderboardTable controls={leaderboardControls} />
    </Leaderboard>
  );
};

export default PeerToPeerPublicLeaderboard;
