import { Avatar, List } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { setCampaign } from '../../reducers/oneCampaign';
import ImpactiveModal from '@web/components/common/ImpactiveModal';
import ImpactiveLink from './Link';
import { PlusOutlined } from '@ant-design/icons';
import { campaigns as campaignsAPI } from '@web/services/api';
import { getLocalizedOnboardingData } from '../../utils/other';
import { useTranslation } from 'react-i18next';
import { replaceDefaultCampaignImage } from '@web/utils/string';

const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  cursor: pointer;
  padding: 1rem;
  border-radius: 12px;
  &:hover {
    background: ${({ theme }) => theme.colors.blue06};
  }
`;

const CampaignDataWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.blue};
  margin-bottom: 3rem;
`;

const NameAndStatus = styled.div`
  margin-left: 20px;
`;

const Name = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.blue};
    font-family: ${theme.fonts.semibold};
    font-size: 1rem;
  `}
`;
const ActiveNow = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => css`
    color: ${theme.colors.coral};
    font-family: ${theme.fonts.semibold};
    font-size: 0.875rem;
  `}
`;

const Dot = styled.div`
  width: 6px;
  height: 6px;
  background: ${({ theme }) => theme.colors.coral};
  border-radius: 50%;
  margin-right: 5px;
`;

const JoinSection = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => css`
    color: ${theme.colors.blackL32};
    font-family: ${theme.fonts.regular};
    font-size: 1rem;
  `}
`;

const PlusSection = styled.div`
  width: 54px;
  height: 54px;
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.09);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.coral};
  margin-right: 20px;
`;

const StickyBlock = styled.div`
  position: sticky;
  bottom: -25px;
  background: white;
`;

export const CampaignAndCharityModal = ({ title, list, onClose, onJoinCampaign }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const campaign = useSelector(state => state.oneCampaign.selectedCampaign);

  const selectCampaign = (id, isActive) => {
    if (isActive) return;
    campaignsAPI.getCampaign({ id }).then(({ data: { data } }) => {
      const localizedCampaign = getLocalizedOnboardingData(data);
      dispatch(setCampaign(localizedCampaign));
      onClose();
    });
  };

  const renderCampaign = item => {
    const isActive = campaign?.id === item.campaign.id;
    return (
      <ListItem onClick={() => selectCampaign(item.campaign_id, isActive)}>
        <CampaignDataWrapper>
          <Avatar size={54} src={replaceDefaultCampaignImage(item.campaign.url_profile_img)} />
          <NameAndStatus>
            <Name>{item.campaign.name}</Name>
            {isActive && (
              <ActiveNow>
                <Dot /> {t('common.active_now')}
              </ActiveNow>
            )}
          </NameAndStatus>
        </CampaignDataWrapper>
        <ImpactiveLink onClick={e => e.stopPropagation()} to={`/campaigns/${item.campaign.slug}`}>
          {t('common.view_details')}
        </ImpactiveLink>
      </ListItem>
    );
  };

  return (
    <ImpactiveModal width={'40%'} visible onCancel={onClose}>
      <Title>{title}</Title>
      <List itemLayout="horizontal" dataSource={list} renderItem={renderCampaign} />
      <StickyBlock>
        <ListItem>
          <div as="button" onClick={onJoinCampaign}>
            <JoinSection>
              <PlusSection>
                <PlusOutlined />
              </PlusSection>
              {t('button.join_another_campaign')}
            </JoinSection>
          </div>
        </ListItem>
      </StickyBlock>
    </ImpactiveModal>
  );
};

CampaignAndCharityModal.propTypes = {
  list: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onJoinCampaign: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

CampaignAndCharityModal.defaultProps = {};

export default CampaignAndCharityModal;
