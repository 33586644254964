import usePatchThrough from '@web/components/DialerActivity/usePatchThrough';
import { Spin } from 'antd';
import React, { useEffect } from 'react';
import styled from 'styled-components';

const StyledSurvey = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;

const SurveyPatchThroughSuccess = () => {
  const { handlePatchThroughCallOutcome } = usePatchThrough();

  useEffect(() => {
    handlePatchThroughCallOutcome();
  }, [handlePatchThroughCallOutcome]);

  return (
    <StyledSurvey>
      <Spin />
    </StyledSurvey>
  );
};

SurveyPatchThroughSuccess.propTypes = {};

export default SurveyPatchThroughSuccess;
