import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useCallback } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { identify, track } from '../../services/analytics';
import { auth, users } from '../../services/api';
import { AuthContext } from '../../utils/context';
import { isAuthFinished } from '@web/utils/other';
import { getRedirectAfterLoginURL, getAndClearRedirectAfterLoginURL } from 'web/services/session';
import Loading from '../common/Loading';
import { setAppLanguage } from '../i18n';
import AuthMenu from './Components/AuthMenu';
import ImpactiveOrCampaignLogo from './Components/ImpactiveOrCampaignLogo';
import PoweredByImpactive from './Components/PoweredByImpactive';
import LanguageSelect from './LanguageSelect';

const AuthPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { campaign_slug } = useParams();

  const title = useMemo(
    () => (campaign_slug ? t('auth.sign_up') : t('auth.login')),
    [campaign_slug, t],
  );
  useDocumentTitle(title);

  const { setAuth, setUser, onboardingData, campaign, isLoading, setCampaignSlug } =
    useContext(AuthContext);

  useEffect(() => {
    // Track the page view
    track('VIEW_AUTH_SCREEN');
    setCampaignSlug(campaign_slug);
  }, [campaign_slug, setCampaignSlug]);

  // In the case of google auth we need to perform additional actions
  // to join the campaign if user came from any of the campaign signup pages
  const onGoogleAuthSuccess = useCallback(
    async data => {
      const { token, user } = data;
      setAuth(token);
      setUser(user);
      setAppLanguage(onboardingData.language || user.locale);
      identify(user.id);

      if (onboardingData.language !== user.locale) {
        users.updateMe({ locale: onboardingData.language || user.locale }).then(({ data }) => {
          setUser(data.data);
        });
      }

      const canViewAdmin = user.is_campaign_admin || user.can_admin_accounts;
      // If the user can access admin and no redirectURL is set - go to admin
      const hasRedirectURL = !!getRedirectAfterLoginURL();
      if (canViewAdmin && !hasRedirectURL) {
        // hard reload (switch to) admin react app
        window.location.href = '/admin';
        return;
      }

      // Join the campaign if auth happened on campaign screen
      if (campaign_slug && campaign) {
        await users.followCampaign({
          campaign_id: campaign.id,
          campaign_name: campaign.name,
          campaign_slug: campaign.slug,
        });

        users.updateMe({ last_campaign_id: campaign.id }).then(({ data: { data } }) => {
          setUser(data);
        });

        if (isAuthFinished(user)) {
          const redirectURL = getAndClearRedirectAfterLoginURL();
          if (redirectURL) {
            history.push(redirectURL);
          }
        } else {
          history.push(`/auth/join-campaign/${campaign.slug}`);
        }
        return;
      }

      // Fallback throw the user to /frontline
      history.push(isAuthFinished(user) ? `/frontline` : `/auth/join-campaign`);
    },
    [campaign, campaign_slug, history, onboardingData.language, setAuth, setUser],
  );

  const unsetAuthHandler = useCallback(
    ({ response }) => {
      // unset auth token
      setAuth(null);

      // display error message
      const { data: { error, message: errorMessage } = {} } = response;
      message.error(errorMessage || error || t('errors.action_failed'));
    },
    [setAuth, t],
  );

  const onLogInWithGoogle = useCallback(
    async (email, accessToken, googleId) => {
      return auth
        .signInWithGoogle(email, accessToken, googleId, onboardingData.language)
        .then(res => onGoogleAuthSuccess(res))
        .catch(unsetAuthHandler);
    },
    [onGoogleAuthSuccess, unsetAuthHandler, onboardingData.language],
  );

  const onPasswordLoginClick = useCallback(() => {
    history.push(campaign_slug ? `/auth/login/${campaign_slug}/email` : '/auth/login/email');
  }, [campaign_slug, history]);

  const onPasswordlessClick = useCallback(() => {
    history.push(
      campaign_slug ? `/auth/signup/${campaign_slug}/passwordless` : '/auth/login/passwordless',
    );
  }, [campaign_slug, history]);

  if (isLoading) {
    return (
      <div className="d-flex flex-row justify-content-center">
        <Loading />
      </div>
    );
  }

  if (!onboardingData.language) {
    return <LanguageSelect />;
  }

  return (
    <div
      className={`h-100 bg-auth-cyan d-flex flex-column align-items-center justify-content-center`}
    >
      <ImpactiveOrCampaignLogo campaign={campaign} />

      <div className="card fixed-auth">
        <div className={`card-title bolder mb-5`}>{t('auth.sign_in_or_sign_up')}</div>

        <AuthMenu
          onGoogleLogin={onLogInWithGoogle}
          onEmailLogIn={onPasswordLoginClick}
          onPasswordlessLogin={onPasswordlessClick}
          passwordlessButtonText={t('auth.enter_email')}
          includePasswordOption={false}
          hidePasswordLessText
        />

        <div className="d-flex flex-row align-items-center justify-content-center mt-5 text-center">
          <div className="text-underline pointer" onClick={onPasswordLoginClick}>
            {t('auth.sign_in_with_password')}
          </div>
        </div>
      </div>

      {campaign && <PoweredByImpactive />}
    </div>
  );
};

AuthPage.propTypes = {
  isLanguageSelect: PropTypes.bool,
  isSignup: PropTypes.bool,
};

export default AuthPage;
