import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// icons
import Connect from '../../icons/Connect';
import PhoneNumber from '../../icons/PhoneNumber';
import Phone from '../../icons/Phone';

import InstructionItem from './InstructionItem';

const StyledInstructions = styled.div`
  padding-top: 4px;
`;

function Instructions() {
  const { t } = useTranslation();

  return (
    <StyledInstructions>
      <InstructionItem icon={<Connect />} title={`1. ${t('activity.phone_bank.grab_phone')}`}>
        {t('activity.phone_bank.grab_phone_description')}
      </InstructionItem>
      <InstructionItem icon={<PhoneNumber />} title={`2. ${t('activity.phone_bank.receive_call')}`}>
        {t('activity.phone_bank.receive_call_description')}
      </InstructionItem>
      <InstructionItem icon={<Phone />} title={`3. ${t('activity.phone_bank.queue_first_call')}`}>
        {t('activity.phone_bank.queue_first_call_description')}
      </InstructionItem>
    </StyledInstructions>
  );
}

export default Instructions;
