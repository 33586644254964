import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CallEnded from './components/CallEnded';
import ConnectingVoter from './components/ConnectingVoter';
import PhoneBankEnded from './components/PhoneBankEnded';
import PhoneBankNotStarted from './components/PhoneBankNotStarted';
import PhoneBankPaused from './components/PhoneBankPaused';
import SessionEnded from './components/SessionEnded';
import VolunteerIdle from './components/VolunteerIdle';
import VoterConnected from './components/VoterConnected';

import { useSelector } from 'react-redux';

import {
  autoConnectLoader,
  callOutcome,
  connectingToVoter,
  idle,
  patchThroughConnected,
  patchThroughSuccess,
  phoneBankEnded,
  phoneBankNotStarted,
  phoneBankPaused,
  sessionEnded,
  sessionStats,
  tagsAndCustomFields,
  viewResponses,
  voterConnected,
} from '@web/reducers/dialerReducer/dialerMachine';
import PatchedThroughCall from './components/PatchThroughCall';

const StyledCallStatus = styled.div`
  border-left: 3px solid #ededed;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
  position: relative;
`;

function CallStatus({ activity }) {
  const { currentState } = useSelector(state => state.dialer);

  if (currentState === 'sessionEnded') {
    return null;
  }

  function getContent() {
    const props = { activity };
    switch (currentState) {
      case callOutcome:
        return <CallEnded {...props} />;
      case connectingToVoter:
        return <ConnectingVoter />;
      case idle:
        return <VolunteerIdle {...props} />;
      case autoConnectLoader:
        return <VolunteerIdle {...props} content={<div>Loading...</div>} />;
      case phoneBankEnded:
        return <VolunteerIdle content={<PhoneBankEnded />} {...props} />;
      case phoneBankNotStarted:
        return <VolunteerIdle content={<PhoneBankNotStarted />} {...props} />;
      case phoneBankPaused:
        return <VolunteerIdle content={<PhoneBankPaused />} {...props} />;
      case sessionEnded:
        return <SessionEnded />;
      case sessionStats:
        return <CallEnded {...props} />;
      case viewResponses:
        return <CallEnded {...props} />;
      case tagsAndCustomFields:
        return <CallEnded {...props} />;
      case voterConnected:
        return <VoterConnected {...props} />;
      case patchThroughSuccess:
        return <CallEnded />;
      case patchThroughConnected:
        return <PatchedThroughCall {...props} />;
      default:
        return null;
    }
  }
  return <StyledCallStatus>{getContent()}</StyledCallStatus>;
}

CallStatus.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
};

CallStatus.defaultProps = {};

export default CallStatus;
