import React from 'react';
import { statFormatted } from '@web/utils/other';

export const columns = [
  {
    accessor: 'place',
    Cell: row => {
      return (
        <>
          {row.value}
          &nbsp;&nbsp;&nbsp;&nbsp;
          {row.original.first_name} {row.original.last_name?.[0]}.
        </>
      );
    },
    Header: 'User',
    width: '50%',
  },
  {
    accessor: 'total_attempted_contacts',
    Cell: row => statFormatted(row.value),
    Header: 'Initial Messages',
    width: '50%',
  },
];
