import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import Button from '@web/components/DialerActivity/components/Button';

const StyledButton = styled(Button)`
  font-size: 16px;
  padding: 0 30px;
  width: 100%;
`;

const StyledReadyDialog = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.lightCyan};
  border-radius: 7px;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: space-between;
  padding: 24px;
  position: sticky;
  bottom: 20px;
  left: 0;
  right: 0;
`;

function ReadyDialog({ sessionState }) {
  const { t } = useTranslation();

  const to = React.useMemo(() => sessionState === 'running' && 'in-call', [sessionState]);
  const onClick = React.useCallback(
    () => !to && message.error(t('activity.phone_bank.not_started')),
    [to, t],
  );

  return (
    <StyledReadyDialog>
      <StyledButton to={to} onClick={onClick}>
        {t('button.ready_to_participate')}
      </StyledButton>
    </StyledReadyDialog>
  );
}

ReadyDialog.propTypes = {
  sessionState: PropTypes.string,
};

ReadyDialog.defaultProps = {};

export default ReadyDialog;
