import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

import activityRoutes from '@web/services/api/activities';
import { message } from 'antd';

const useLeaderboard = () => {
  const params = useParams();

  const [loading, setLoading] = useState(true);

  const [hasMore, setHasMore] = useState(false);

  const [leaderboard, setLeaderboard] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({ page: 0, perPage: 10 });

  const [publicCampaign, setPublicCampaign] = useState(null);
  const [publicActivity, setPublicActivity] = useState(null);

  const getData = useCallback(
    type => {
      activityRoutes
        .getP2PUsersLeaderboard({
          activitySlug: params.activitySlug,
          campaignSlug: params.campaignSlug,
          page: paginationConfig.page + 1,
          perPage: paginationConfig.perPage,
        })
        .then(({ data }) => {
          setLeaderboard(data.data);
          setPublicActivity(data.activity);
          setPublicCampaign(data.campaign);
          setHasMore(!!data.links.next);
          setLoading(false);
        })
        .catch(() => {
          message.error('Something went wrong, please try again');
          setLoading(false);
        });
    },
    [paginationConfig.page, paginationConfig.perPage, params.activitySlug, params.campaignSlug],
  );

  useEffect(() => {
    getData();
  }, [getData]);

  // Update leaderboard every 5min
  useEffect(() => {
    const interval = setInterval(() => {
      getData();
    }, 300000);

    return () => clearInterval(interval);
  }, [getData]);

  return {
    activity: publicActivity,
    campaign: publicCampaign,
    hasMore,
    leaderboard,
    loading,
    paginationConfig,
    setPaginationConfig,
  };
};

export default useLeaderboard;
