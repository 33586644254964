import { Input, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { track } from '../../../services/analytics';
import { isValidEmail } from '../../../utils/string';
import ImpactiveButton from '../../ImpactiveButton';

const StyledButton = styled(ImpactiveButton)`
  text-transform: none;
`;

const EmailAuth = ({ onSubmitClick, buttonText }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Track the page view
  useEffect(() => {
    track('VIEW_EMAIL_AUTH_SCREEN');
  }, []);

  const hasErrors = () => {
    // validate input, only submit for valid email addresses
    if (!isValidEmail(email)) {
      message.error(t('auth.email.invalid'));
      return true;
    }
    // validate password
    if (!password) {
      message.error(t('auth.email.enter_password'));
      return true;
    }
    return false;
  };

  const handleSubmit = e => {
    track('CLICK_LOGIN');
    e.preventDefault();

    if (hasErrors()) {
      return;
    }
    onSubmitClick(email, password);
  };

  return (
    <form
      data-testid="auth-form"
      onSubmit={handleSubmit}
      className="d-flex flex-column align-items-center w-100"
    >
      <Input
        autoFocus
        type="email"
        name="email"
        placeholder={t('placeholders.email')}
        onChange={e => setEmail(e.target.value)}
      />
      <Input
        type="password"
        placeholder={t('placeholders.password')}
        className="my-3"
        onChange={e => setPassword(e.target.value)}
      />

      <StyledButton size="large" htmlType="submit">
        {buttonText}
      </StyledButton>
    </form>
  );
};

EmailAuth.propTypes = {
  buttonText: PropTypes.string,
  onSubmitClick: PropTypes.func.isRequired,
};

EmailAuth.defaultProps = {
  buttonText: 'Sign In',
};

export default EmailAuth;
