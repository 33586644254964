import { shape } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { user as userAPI, campaigns } from 'web/services/api';
import { maybeGetToken, removeToken, storeToken } from '../services/session';
import { AuthContext } from '../utils/context';

const AuthProvider = ({ children }) => {
  // Logged in user info
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Initial state is fethed from localStorage to achieve cross-session data persistance.
  const [token, setToken] = useState(maybeGetToken());
  const [onboardingData, setOnboardingData] = useState({
    campaignId: null,
    firstName: null,
    language: null,
    lastName: null,
    phone: null,
    zip_code: null,
  });

  // campaign states
  const [campaignSlug, setCampaignSlug] = useState(null);
  const [isCampaignLoading, setIsCampaignLoading] = useState(true);
  const [campaign, setCampaign] = useState(null);

  useEffect(() => {
    if (!campaignSlug) {
      setIsCampaignLoading(false);
      setCampaign(null);
      return;
    }

    campaigns
      .getPublicCampaign({ id: campaignSlug })
      .then(({ data: { data } }) => {
        setCampaign(data);
        setIsCampaignLoading(false);
      })
      .catch(() => {
        setCampaign(null);
        setIsCampaignLoading(false);
      });
  }, [campaignSlug]);

  useEffect(() => {
    // Fetch user info when logged in and onboarding finished (onboarding updates user profile)
    setLoading(true);
    if (token) {
      userAPI
        .getProfile()
        .then(({ data: { data } }) => setUser(data))
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [token]);

  const setAuth = useCallback(newToken => {
    if (newToken) {
      storeToken(newToken);
      setToken(newToken);
    } else {
      removeToken();
      setToken(null);
    }
  }, []);

  // There is a case when token gets invalidated by axios interceptor handler, we need to update it in that case
  if (token !== maybeGetToken()) {
    setAuth(maybeGetToken());
  }

  // NOTE: if this provider gets any bigger it would be good to convert this to store w/ simple reducer (not Redux!)
  return (
    <AuthContext.Provider
      value={{
        campaign,
        isCampaignLoading,
        isLoading: isCampaignLoading || loading,
        isLockedToCampaign: !!(user && user.locked_campaign_id),
        loading,
        onboardingData,
        setAuth,
        setCampaignSlug,
        setOnboardingData,
        setToken,
        setUser,
        token,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: shape({}).isRequired,
};

export default AuthProvider;
