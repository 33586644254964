import * as React from 'react';
import Pusher from 'pusher-js';
import { message } from 'antd';
import axios from 'axios';
import { addBreadcrumb } from '@web/utils/sendError';
import { PusherMock } from 'pusher-js-mock';

import {
  PATCH_THROUGH_CONNECTED,
  patchThroughConnected,
  PHONE_BANK_ENDED,
  PHONE_BANK_NOT_STARTED,
  PHONE_BANK_PAUSED,
  PHONE_BANK_STARTED,
  VOLUNTEER_DISCONNECTED,
  VOLUNTEER_HANG_UP,
  VOTER_CONNECTED,
  VOTER_HANG_UP,
} from '@web/reducers/dialerReducer/dialerMachine';
import { machineTransition, setDisabledPatchThrough } from '@web/reducers/dialerReducer';
import sendError from '@web/utils/sendError';
import useHangUp from './useHangUp';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { voterJoined } from '@web/reducers/dialerReducer';

const DEBUG_PUSHER = false;

let pusher;

// Mock pusher when Cypress is present on window
if (window?.Cypress) {
  pusher = new PusherMock();
} else {
  pusher = new Pusher(process.env.PUSHER_APP_KEY, {
    cluster: process.env.PUSHER_APP_CLUSTER,
  });
}

const endedStates = ['graceful_shutdown', 'terminated', 'completed'];
const notStartedStates = ['created'];

Pusher.log = msg => {
  if (DEBUG_PUSHER) {
    const pingRe = /ping/g;
    const pongRe = /pong/g;
    if (pingRe.test(msg)) {
      return;
    }
    if (pongRe.test(msg)) {
      return;
    }
    // eslint-disable-next-line
    console.log(msg);
  }
};

function usePusher(activity, audioRef) {
  const { callData, currentState } = useSelector(state => state.dialer);
  const dispatch = useDispatch();
  const stateChannelName = `activity;${activity.id};voice-call-pool`;
  const callId = callData?.call_id;
  const { handleVoterHangUp } = useHangUp();

  // MTS - We assume the dialer is in a running state and we
  // check each time the user makes a return to the idle state
  function getDialerState() {
    axios(`/api/v2/activities/${activity.id}/voice_call_pool`)
      .then(({ data }) => {
        // Check to see if the phone bank has ended
        if (endedStates.find(el => el === data.aasm_state)) {
          return dispatch(machineTransition(PHONE_BANK_ENDED));
        }

        if (data.aasm_state === 'paused') {
          return dispatch(machineTransition(PHONE_BANK_PAUSED));
        }

        // Check to see if the phone bank is not started
        if (notStartedStates.find(el => el === data.aasm_state)) {
          dispatch(machineTransition(PHONE_BANK_NOT_STARTED));
        }
      })
      .catch(err => {
        sendError('Dialer error - getDialerState usePusher', {
          request: err?.request,
          response: err?.response,
        });
      });
  }

  function stateChannelSubscribe(stateChannel) {
    stateChannel.bind('state-change', handleStateChange);
  }

  function handleStateChange(data) {
    if (data?.state === 'running') {
      message.info(`The phone bank's session has now started`);
      return dispatch(machineTransition(PHONE_BANK_STARTED));
    }

    if (data?.state === 'paused') {
      return dispatch(machineTransition(PHONE_BANK_PAUSED));
    }

    const hasEnded = !!endedStates.find(el => el === data?.state);

    if (hasEnded) {
      dispatch(machineTransition(PHONE_BANK_ENDED));
    }
  }

  const handlePatchThroughJoined = React.useCallback(
    data => {
      addBreadcrumb({
        category: 'Dialer',
        data,
        level: 'log',
        message: 'Pusher - patch_through_joined event received',
      });

      dispatch(machineTransition(PATCH_THROUGH_CONNECTED));
      dispatch(setDisabledPatchThrough([]));
    },
    [dispatch],
  );

  const handleCreatePatchThroughCallFailed = React.useCallback(
    data => {
      addBreadcrumb({
        category: 'Dialer',
        data,
        level: 'log',
        message: 'Pusher - create-patch-through-call-failed event received',
      });
      // reset disabled patch through references
      dispatch(setDisabledPatchThrough([]));
      if (currentState === patchThroughConnected) {
        dispatch(machineTransition(VOTER_CONNECTED));
      }
    },
    [currentState, dispatch],
  );

  const handlePatchThroughCallEnded = React.useCallback(
    data => {
      addBreadcrumb({
        category: 'Dialer',
        data,
        level: 'log',
        message: 'Pusher - patch-through-call-ended event received',
      });
      // reset disabled patch through references
      dispatch(setDisabledPatchThrough([]));
      dispatch(machineTransition(VOLUNTEER_HANG_UP));
    },
    [dispatch],
  );

  const handleVoterLeft = React.useCallback(
    data => {
      addBreadcrumb({
        category: 'Dialer',
        data,
        message: 'Pusher - voter_left event received',
      });
      handleVoterHangUp(VOTER_HANG_UP, data?.voter_voice_call_id);
    },
    [handleVoterHangUp],
  );

  const handleVolunteerLeft = React.useCallback(() => {
    addBreadcrumb({
      category: 'Dialer',
      message: 'Pusher - volunteer_left event received',
    });
    dispatch(machineTransition(VOLUNTEER_DISCONNECTED));
  }, [dispatch]);

  const handleVoterJoined = React.useCallback(
    data => {
      // Try to play notification
      if (audioRef?.current) {
        try {
          audioRef?.current?.play();
        } catch (err) {
          sendError('Dialer - Error playing notification', err);
        }
      }
      message.info(`You are now connected to ${data.full_name}`);
      addBreadcrumb({
        category: 'Dialer',
        data,
        message: 'Pusher - voter_joined event received',
      });
      dispatch(voterJoined(data));
      dispatch(machineTransition(VOTER_CONNECTED));
    },
    // eslint-disable-next-line
    [audioRef, dispatch],
  );

  function handlePusherConnectionChange({ current, previous }) {
    addBreadcrumb({
      category: 'Dialer - Pusher',
      message: `Pusher Connection State Change | from: ${previous} to: ${current}`,
    });
  }

  const subscribeToEvents = React.useCallback(
    channel => {
      channel.bind('voter_joined', handleVoterJoined);
      channel.bind('voter_left', handleVoterLeft);
      channel.bind('volunteer_left', handleVolunteerLeft);
      channel.bind('patch_through_joined', handlePatchThroughJoined);
      channel.bind('create-patch-through-call-failed', handleCreatePatchThroughCallFailed);
      channel.bind('patch-through-call-ended', handlePatchThroughCallEnded);
    },
    [
      handleVoterJoined,
      handleVoterLeft,
      handleVolunteerLeft,
      handlePatchThroughJoined,
      handleCreatePatchThroughCallFailed,
      handlePatchThroughCallEnded,
    ],
  );

  // MTS - Pusher Subscribe/unsubscribe when the callId changes
  // The callId can change if a volunteer gets disconnected and
  // reconnected.
  React.useEffect(() => {
    if (callId) {
      addBreadcrumb({
        category: 'Dialer - Pusher',
        message: `Subscribing to channel ${callId}`,
      });
      const thisChannel = pusher.subscribe(callId.toString());
      subscribeToEvents(thisChannel);
    }

    return () => {
      if (callId) {
        addBreadcrumb({
          category: 'Dialer Pusher',
          message: `Unsubscribing from channel ${callId}`,
        });
        pusher.unsubscribe(callId?.toString());
      }
    };
    // eslint-disable-next-line
  }, [callId]);

  // Subscribe to the other pusher channels on mount
  React.useEffect(() => {
    pusher.connection.bind('state_change', handlePusherConnectionChange);
    const stateChannel = pusher.subscribe(stateChannelName);
    addBreadcrumb({
      category: 'Dialer - Pusher',
      message: `Subscribing to channel ${stateChannelName}`,
    });
    stateChannelSubscribe(stateChannel);
    // Required for e2e tests
    if (window?.Cypress) {
      window.Cypress.pusher = pusher;
    }
    return () => {
      addBreadcrumb({
        category: 'Dialer - Pusher',
        message: `Unsubscribing from channel ${stateChannelName}`,
      });
      pusher.unsubscribe(stateChannelName);
    };
    // eslint-disable-next-line
  }, []);

  // Run this hook each time the currentState switches to idle
  React.useEffect(() => {
    if (currentState === 'idle') {
      getDialerState();
    }
    // eslint-disable-next-line
  }, [currentState]);
}

export default usePusher;
