import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useIsMounted from '@web/hooks/useIsMounted';
import useHangUp from './pages/InCall/useHangUp';
import {
  CALL_SUCCESSFUL,
  connectingToVoter,
  VOLUNTEER_HANG_UP,
} from '@web/reducers/dialerReducer/dialerMachine';
import sendError, { addBreadcrumb } from '@web/utils/sendError';
import { machineTransition, setDisabledPatchThrough } from '@web/reducers/dialerReducer';
import { message } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const usePatchThrough = () => {
  const [isTerminating, setIsTerminating] = useState(false);
  const dispatch = useDispatch();
  const { callData, currentState, voter_call_id } = useSelector(state => state.dialer);
  const { checkConferenceIdle, handleHangUp } = useHangUp();
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  // MTS - Assign refs because we are mysteriously getting stale values
  const callIdRef = useRef(voter_call_id);
  const currentStateRef = useRef(currentState);
  // Necessary to prevent stale values
  useEffect(() => {
    callIdRef.current = voter_call_id;
    currentStateRef.current = currentState;
  }, [currentState, voter_call_id]);

  /**
   * This function do the same thing as handleSubmit in the useCallOutcomeQuestions hook that we use for regular phone banking
   * The main difference that we have to always send "completed" as the reference
   * because if the user reach this place it meant that the call was successful and we need to send "completed" to API to update metrics
   */
  const handlePatchThroughCallOutcome = useCallback(() => {
    // Just use the default flow to not interrupt the user
    // in this case we will not update the metrics, but the call will complete successfully
    dispatch(machineTransition(CALL_SUCCESSFUL));

    const voterCallId = callIdRef.current;
    const url = `/api/v2/voter_voice_calls/${voterCallId}/voice_call_outcome`;

    axios
      .patch(url, { reference: 'completed' })
      .then(res => {
        // we don't need to do anything here
      })
      .catch(err => {
        sendError('[PATCH- THROUGH] Error submitting voice call outcome', err);
      });
  }, [dispatch]);

  // RH - this is mostly a copy of app/screens/DialerActivityScreen/screens/DialerActive/components/SessionBanner/components/HangUpButton/HangUpButton.js and will be rewritten when on terminate call only third party person will be disconnected
  const handleTerminate = useCallback(() => {
    if (isTerminating) return;

    setIsTerminating(true);
    const volunteerCallId = callData?.call_id;
    const url = `/api/v2/volunteer_voice_calls/${volunteerCallId}/voter_voice_call`;
    // MTS - I need to keep track of the value at the time the function was called not
    // the current value, which could be different.
    const voterCallId = callIdRef.current;
    axios
      .delete(url)
      .then(res => {
        handleHangUp(VOLUNTEER_HANG_UP, voterCallId);
      })
      .catch(async err => {
        // See https://github.com/outvote/web-app/issues/7561 for details
        if (err?.response?.status === 422 && currentStateRef?.current === connectingToVoter) {
          const conferenceIdle = await checkConferenceIdle();
          // The user is in an out of sync state with the backend and we need to transition them
          // back to idle
          if (conferenceIdle) {
            addBreadcrumb({
              category: 'dialer',
              message:
                'Dialer - Transitioning user back to idle from 422 hang up in connectingToVoter',
            });
            return dispatch(machineTransition(VOLUNTEER_HANG_UP));
          }
        }

        sendError('Dialer - Unable to hang up call', {
          request: err?.request,
          response: err?.response,
        });
        // MTS - This request can resolve after the component is unmounted
        // we only show the message while it is mounted.
        if (isMounted()) {
          message.error('An error occurred while hanging up the call. Please try again.');
        }
      })
      .finally(() => {
        setIsTerminating(false);
      });
  }, [isTerminating, callData?.call_id, handleHangUp, isMounted, checkConferenceIdle, dispatch]);

  // RH - this is a new function that will be used to patch through a third party person but also uses MTS code approach
  const handlePatchThrough = useCallback(() => {
    // MTS - I need to keep track of the value at the time the function was called not
    // the current value, which could be different.
    const volunteerCallId = callData?.call_id;
    const url = `/api/v2/client/user_voice_calls/${volunteerCallId}/patch_through_requests`;

    axios
      .post(url)
      .then(res => {
        // we subscribed for `patch_through_joined` event in the machine, but it could be also triggered by the next action
        // dispatch(machineTransition(PATCH_THROUGH_CONNECTED))
      })
      .catch(async err => {
        sendError('Dialer - Unable to patch through a call', {
          request: err?.request,
          response: err?.response,
        });
        dispatch(setDisabledPatchThrough([]));
        // MTS - This request can resolve after the component is unmounted
        // we only show the message while it is mounted.
        if (isMounted()) {
          if (err?.response?.status === 404) {
            message.error(t('activity.phone_bank.patch_through.error404'));
          }
          if (err?.response?.status === 409) {
            message.error(t('activity.phone_bank.patch_through.error409'));
          }
        }
      });
  }, [callData?.call_id, dispatch, isMounted, t]);

  return { handlePatchThrough, handlePatchThroughCallOutcome, handleTerminate };
};

export default usePatchThrough;
