import * as React from 'react';
import PropTypes from 'prop-types';

import { Body, Title } from '@web/components/DialerActivity/components/TextStyles';
import BackButton from '../../components/BackButton';
import ConnectIcon from '@web/components/DialerActivity/icons/Connect';
import NextButton from '../../components/NextButton';
import Page, { CopyWrapper, PageCopy } from '../../components/Page';
import { useTranslation } from 'react-i18next';

function GrabPhone({ onBack, onNext }) {
  const { t } = useTranslation();

  return (
    <Page>
      <ConnectIcon size={118} />
      <PageCopy>
        <CopyWrapper>
          <Title>{t('activity.phone_bank.grab_phone')}</Title>
          <Body>{t('activity.phone_bank.grab_phone_description_short')}</Body>
        </CopyWrapper>
      </PageCopy>
      <NextButton onClick={onNext} />
      <BackButton onClick={onBack}>{t('common.cancel')}</BackButton>
    </Page>
  );
}

GrabPhone.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default GrabPhone;
