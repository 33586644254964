import PropTypes from 'prop-types';
import React, { useContext, useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { message } from 'antd';

import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { auth } from '../../services/api';
import { AuthContext } from '../../utils/context';
import Loading from '../common/Loading';
import PasswordlessAuth from './Components/PasswordlessAuth';
import ImpactiveOrCampaignLogo from './Components/ImpactiveOrCampaignLogo';
import PoweredByImpactive from './Components/PoweredByImpactive';
import LanguageSelect from './LanguageSelect';

const PasswordlessPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { campaign_slug } = useParams();
  const { onboardingData, campaign, isLoading, setCampaignSlug } = useContext(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const title = campaign_slug ? t('titles.sign_up') : t('titles.sign_in');
  useDocumentTitle(title);

  useEffect(() => {
    setCampaignSlug(campaign_slug);
  }, [campaign_slug, setCampaignSlug]);

  const onError = useCallback(
    error => {
      message.error(
        error?.response?.data?.message || error?.message || t('common.something_went_wrong'),
      );
      setIsSubmitting(false);
    },
    [t],
  );

  const onEmailSubmit = useCallback(
    email => {
      if (campaign_slug && campaign) {
        auth
          .sendMagicLinkEmail(email, campaign_slug, campaign_slug)
          .then(() => {
            history.push(`/auth/signup/${campaign_slug}/passwordless/confirm`, { email });
          })
          .catch(err => onError(err));
        return;
      } else {
        auth
          .sendMagicLinkEmail(email, 'signup')
          .then(() => {
            history.push('/auth/login/passwordless/confirm', { email });
          })
          .catch(err => onError(err));
        return;
      }
    },
    [campaign, campaign_slug, history, onError],
  );

  const onBackClick = useCallback(() => {
    if (campaign_slug) {
      history.push(`/auth/signup/${campaign_slug}`);
    } else {
      history.push('/auth/login');
    }
  }, [history, campaign_slug]);

  if (isLoading) {
    return (
      <div className="d-flex flex-row justify-content-center">
        <Loading />
      </div>
    );
  }

  if (!onboardingData.language) {
    return <LanguageSelect />;
  }

  return (
    <div className="h-100 bg-auth-cyan d-flex flex-column align-items-center justify-content-center">
      <ImpactiveOrCampaignLogo campaign={campaign} />

      <div className="card fixed-auth">
        <div className="card-title bolder">{t('auth.auth_modal.enter_your_email')}</div>

        <div className="pb-4 text-center">{t('auth.auth_modal.receive_link')}</div>

        <div className="my-3">
          <PasswordlessAuth
            onSubmitClick={onEmailSubmit}
            setIsSubmitting={setIsSubmitting}
            isSubmitting={isSubmitting}
            buttonText={t('auth.auth_modal.continue')}
          />
        </div>

        <div className="d-flex flex-row align-items-center justify-content-center mt-5 text-center">
          <div className="text-underline pointer" onClick={onBackClick}>
            {t('auth.back')}
          </div>
        </div>
      </div>

      {campaign && <PoweredByImpactive />}
    </div>
  );
};

PasswordlessPage.propTypes = {
  isSignup: PropTypes.bool,
};

PasswordlessPage.propTypes = {};

export default PasswordlessPage;
