import styled, { keyframes } from 'styled-components';

const ellipsis = keyframes`
  from { width: 6px; }
  to { width: 21px; }
`;

const TypingDots = styled.span`
  display: inline-block;
  width: 18px;
  &::after {
    content: '...';
    display: flex;
    overflow: hidden;
    vertical-align: bottom;
    animation: ${ellipsis} 1.5s steps(3, end) infinite;
  }
`;

export default TypingDots;
