import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useInterpolation from '@web/components//DialerActivity/useInterpolation';
import { useSelector } from 'react-redux';

const StyledQuestion = styled.h3`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-family: ${({ theme }) => theme.fonts.light};
  font-size: 22px;
  margin-bottom: 8px;
`;

function Question({ text }) {
  const connectedContact = useSelector(state => state.dialer.connectedContact);
  const { interpolated, interpolationLoading } = useInterpolation(text, connectedContact);
  if (interpolationLoading) {
    return null;
  }
  return <StyledQuestion dangerouslySetInnerHTML={{ __html: interpolated }} />;
}

Question.propTypes = {
  text: PropTypes.string.isRequired,
};

Question.defaultProps = {};

export default Question;
